module.exports = {
    publicPath: "./",
    css: {
        loaderOptions: {
            less: {
                lessOptions: {
                    javascriptEnabled: true
                }
            }
        }
    },
    devServer: {
        //内网穿透需要配置
        disableHostCheck:true,
        proxy: {
            '/platform': {
                //target: 'http://localhost:8880/platform/',
                // target: 'http://47.105.69.187:80/platform/',
                target: 'http://localhost/platform',
                // target: 'http://47.105.69.187:88/platform',
                ws: true,
                // 是否开启代理
                changeOrigin: true,
                "secure": false,
                pathRewrite: {
                    '^/platform': ''//这里理解成用‘/api’代替target里面的地址，后面组件中我们掉接口时直接用api代替 比如我要调用'http://40.00.100.100:3002/user/add'，直接写‘/api/user/add’即可
                }
            }
        }
    },
    pwa: {
        iconPaths: {
            favicon32: 'favicon.ico',
            favicon16: 'favicon.ico',
            appleTouchIcon: 'favicon.ico',
            maskIcon: 'favicon.ico',
            msTileImage: 'favicon.ico'
        }
    },
}