<template>
  <div>
    <a-card title="定时任务配置" :bordered="false" style="width: 100%">
      <a-button type="primary" @click="visible=true">创建定时任务</a-button>
      <a-table :columns="columns" :data-source="data" :scroll="{ y: 350 }" size="small" rowKey="id"
               ref="ruleForm">
        <template
            v-for="col in ['timingName', 'beanName', 'methodName','cron','param']"
            :slot="col"
            slot-scope="text, record"
        >
          <div :key="col">
            <a-input
                v-if="record.editable"
                style="margin: -5px 0"
                :value="text"
                @change="e => handleChange(e.target.value, record.id, col)"
            />
            <template v-else>
              {{ text }}
            </template>
          </div>
        </template>

        <span slot="flagInvalid" slot-scope="flagInvalid,record">
          <a-switch :default-checked="record.flagInvalid===0 ? true:false" checked-children='运行中'
                    un-checked-children='已关闭'
                    @change='onChange(record)'></a-switch>
    </span>
        <span slot="time" slot-scope="record">{{ $moment(record.createdTime).format('YYYY-MM-DD HH:mm:SS') }}</span>
        <span slot="updateTime"
              slot-scope="record">{{ $moment(record.updateTime).format('YYYY-MM-DD HH:mm:SS') }}</span>
        <span slot="action" slot-scope="record" style="width: 100px">
          <span v-if="record.editable">
          <a @click="() => save(record.id)">保存</a>
          <a-popconfirm title="确认取消吗?" @confirm="() => cancel(record.id)" style="margin-left: 20px">
            <a>取消</a>
          </a-popconfirm>
        </span>
        <span v-else>
          <a @click="() => edit(record.id)">编辑</a>
        </span>

          <a-popconfirm
              title="确定删除定时任务?"
              ok-text="是"
              cancel-text="否"
              @confirm="confirm(record)"
              @cancel="canceldel(record)"
          >
            <a-button type="danger" size="small" style="margin-left: 10px">删除</a-button>
          </a-popconfirm>
    </span>
      </a-table>
    </a-card>
    <!--    创建模板弹窗-->
    <a-modal v-model="visible" title="创建定时任务" @ok="handleOk()" okText="确认" cancelText="取消"
    >
      <a-form-model :model="form" style="width: 230px">
        <a-form-model-item label="任务名称">
          <a-input v-model="form.timingName" placeholder="任务名称"/>
        </a-form-model-item>
        <a-form-model-item label="类名">
          <a-input v-model="form.beanName"/>
        </a-form-model-item>
        <a-form-model-item label="方法名">
          <a-input v-model="form.methodName"/>
        </a-form-model-item>
        <a-form-model-item label="参数">
          <a-input v-model="form.param"/>
        </a-form-model-item>
        <a-form-model-item label="规则">
          <a-input v-model="form.cron"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <!--    修改编辑-->

  </div>
</template>
<script>
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: '60px'
  },
  {
    title: '任务名称',
    dataIndex: 'timingName',
    key: 'timingName',
    scopedSlots: {customRender: 'timingName'}

  },
  {
    title: '类名',
    dataIndex: 'beanName',
    key: 'beanName',
    scopedSlots: {customRender: 'beanName'}
  },
  {
    title: '方法名',
    dataIndex: 'methodName',
    key: 'methodName',
    scopedSlots: {customRender: 'beanName'}

  },
  {
    title: '规则',
    dataIndex: 'cron',
    key: 'cron',
    scopedSlots: {customRender: 'cron'}

  },
  {
    title: '参数',
    dataIndex: 'param',
    key: 'param',
    scopedSlots: {customRender: 'param'}

  },

  {
    title: '创建时间',
    // dataIndex: 'createdTime',
    // key: 'createdTime',
    width: '180px',
    scopedSlots: {
      customRender: 'time'
    }
  },
  {
    title: '状态',
    width: '80px',
    dataIndex: 'flagInvalid',
    key: 'flagInvalid',
    scopedSlots: {customRender: 'flagInvalid'},
  },
  {
    title: '操作',
    key: 'action',
    width: '180px',
    scopedSlots: {customRender: 'action'},
  },
];

export default {
  data() {
    return {
      data: [],
      cacheData: [],
      // 开关状态
      isChecked: true,
      columns,
      // 添加分区显示
      visible: false,
      // 模拟充值是否显示
      isInvest: false,
      // 修改编辑显示
      isEdit: false,

      editingId: '',
      form: {
        id: undefined,
        // 类名称
        beanName: 'settlementServiceImpl',
        //方法名称
        methodName: 'testwo',
        // 附带参数
        param: '123',
        // 规则
        cron: '*/30 * * * * ?',

        createTime: '',


        lastTime: '',

        flagInvalid: '',
        timingName: ''
      }
      ,
    }
        ;
  },
  methods: {
    // 初始化方法
    async init() {
      // 获取分区列表
      const {data: res} = await this.$http.post('/platform/timing/list')
      if (res.code === 0) {
        this.data = res.list;
      }
    },
    handleChange(value, id, column) {
      const newData = [...this.data];
      const target = newData.filter(item => id === item.id)[0];
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },
    edit(id) {
      const newData = [...this.data];
      const target = newData.filter(item => id === item.id)[0];
      this.editingId = id;
      if (target) {
        target.editable = true;
        this.data = newData;
      }
    },
    cancel(id) {
      console.log(id)
      this.init();
    },

    // 创建任务
    async handleOk() {

      const {data: res} = await this.$http.post('/platform/timing/add', this.form)
      if (res.code === 0) {
        this.$message.success('创建成功!')
        // 刷新列表
        this.init();
        // 重置各个参数
        this.visible = false
      } else {
        this.$message.error('创建失败!')
      }

    },

    // 编辑保存
    async save(id) {
      const newData = [...this.data];
      // const newCacheData = [...this.cacheData];
      const target = newData.filter(item => id === item.id)[0];
      // const targetCache = newCacheData.filter(item => id === item.id)[0];
      // if (target && targetCache) {
      //   delete target.editable;
      //   this.data = newData;
      //   Object.assign(targetCache, target);
      //   this.cacheData = newCacheData;
      // }
      this.editingKey = '';
      const {data: res} = await this.$http.post('/platform/timing/update', target)
      if (res.code == 0) {
        this.$message.success('修改成功!')
        this.editingKey = ''
        this.editable = true
        // 刷新列表
        this.init();

      } else {
        this.$message.error(res.message)
      }

    },
    // 确认删除方法
    async confirm(param) {
      const {data: res} = await this.$http.post('/platform/timing/del', param)
      if (res.code === 0) {
        this.$message.success('删除成功!')
        // 刷新列表
        this.init();
      } else {
        this.$message.error('删除失败!')
      }
    },
    //取消删除
    canceldel() {
    },
    async onChange(val) {
      if (val.flagInvalid == 1) {
        // 开启
        val.flagInvalid = 0
      } else {
        val.flagInvalid = 1
        // 关闭
      }
      const {data: res} = await this.$http.post('/platform/timing/updateState', val)
      if (res.code === 0) {
        this.$message.success(res.message)
        // 刷新列表
      } else {
        this.$message.error(res.message)
        this.init();
      }

    }
  },
  mounted() {
    this.init()
  }
};
</script>
