var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"big_box"},[_c('div',{staticClass:"main-cont-wrap"},[_c('div',{staticStyle:{"height":"50px"}},[_c('div',{staticStyle:{"font-size":"20px","color":"#1E90FF","display":"inline"}},[_vm._v(" 注 册 ")]),_c('div',{staticStyle:{"font-size":"16px","color":"#333333","float":"right"},on:{"click":_vm.goLogin}},[_vm._v(" 登 陆 "),_c('a-icon',{attrs:{"type":"caret-right"}})],1)]),_c('div',{staticStyle:{"margin-top":"15px","height":"450px","overflow":"auto"}},[_c('a-form-model',{ref:"ruleForm",attrs:{"rules":_vm.rules,"model":_vm.form}},[_c('a-form-model-item',{ref:"username",staticStyle:{"display":"inline","white-space":"nowrap"},attrs:{"prop":"username"}},[_c('label',[_vm._v("账号: ")]),_c('a-input',{staticStyle:{"margin-left":"10px","width":"250px"},attrs:{"placeholder":"输入账号用来登陆系统"},on:{"blur":function () {
            _vm.$refs.username.onFieldBlur();
          }},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}})],1),_c('a-form-model-item',{ref:"passwordHash",staticStyle:{"display":"inline","white-space":"nowrap"},attrs:{"prop":"passwordHash"}},[_c('label',[_vm._v("密码: ")]),_c('a-input',{staticStyle:{"margin-left":"10px","width":"250px"},attrs:{"type":"password","placeholder":"输入密码"},on:{"blur":function () {
            _vm.$refs.passwordHash.onFieldBlur();
          }},model:{value:(_vm.form.passwordHash),callback:function ($$v) {_vm.$set(_vm.form, "passwordHash", $$v)},expression:"form.passwordHash"}})],1),_c('a-form-model-item',{ref:"userEmail",staticStyle:{"display":"inline","white-space":"nowrap"},attrs:{"prop":"userEmail"}},[_c('label',[_vm._v("邮箱: ")]),_c('a-input',{staticStyle:{"margin-left":"10px","width":"250px"},attrs:{"placeholder":"输入邮箱"},on:{"blur":function () {
            _vm.$refs.userEmail.onFieldBlur();
          }},model:{value:(_vm.form.userEmail),callback:function ($$v) {_vm.$set(_vm.form, "userEmail", $$v)},expression:"form.userEmail"}})],1),_c('a-form-model-item',{ref:"userCardName",staticStyle:{"display":"inline","white-space":"nowrap"},attrs:{"prop":"userCardName"}},[_c('label',[_vm._v("姓名: ")]),_c('a-input',{staticStyle:{"margin-left":"10px","width":"250px"},attrs:{"placeholder":"输入真实姓名"},on:{"blur":function () {
            _vm.$refs.password.onFieldBlur();
          }},model:{value:(_vm.form.userCardName),callback:function ($$v) {_vm.$set(_vm.form, "userCardName", $$v)},expression:"form.userCardName"}})],1),_c('a-form-model-item',{ref:"userCardNo",staticStyle:{"display":"inline","white-space":"nowrap"},attrs:{"prop":"userCardNo"}},[_c('label',[_vm._v("支付宝: ")]),_c('a-input',{staticStyle:{"margin-left":"10px","width":"250px"},attrs:{"placeholder":"输入支付宝账号"},on:{"blur":function () {
            _vm.$refs.userCardNo.onFieldBlur();
          }},model:{value:(_vm.form.userCardNo),callback:function ($$v) {_vm.$set(_vm.form, "userCardNo", $$v)},expression:"form.userCardNo"}})],1),_c('a-form-model-item',{ref:"userQq",staticStyle:{"display":"inline","white-space":"nowrap"},attrs:{"prop":"userQq"}},[_c('label',[_vm._v("微信: ")]),_c('a-input',{staticStyle:{"margin-left":"10px","width":"250px"},attrs:{"placeholder":"输入微信或者QQ"},on:{"blur":function () {
            _vm.$refs.userQq.onFieldBlur();
          }},model:{value:(_vm.form.userQq),callback:function ($$v) {_vm.$set(_vm.form, "userQq", $$v)},expression:"form.userQq"}})],1),_c('a-form-model-item',{ref:"userPhone",staticStyle:{"display":"inline","white-space":"nowrap"},attrs:{"prop":"userPhone"}},[_c('label',[_vm._v("手机: ")]),_c('a-input',{staticStyle:{"margin-left":"10px","width":"250px"},attrs:{"placeholder":"手机号"},on:{"blur":function () {
            _vm.$refs.userPhone.onFieldBlur();
          }},model:{value:(_vm.form.userPhone),callback:function ($$v) {_vm.$set(_vm.form, "userPhone", $$v)},expression:"form.userPhone"}})],1),_c('a-form-model-item',{staticStyle:{"display":"inline","white-space":"nowrap","margin-top":"20px"}},[_c('label',[_vm._v("验证码: ")]),_c('a-input',{staticStyle:{"margin-left":"10px","width":"100px"},attrs:{"placeholder":"验证码"},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}}),_c('img',{staticClass:"img_vcode",staticStyle:{"width":"100px","height":"60px","margin-left":"20px"},attrs:{"alt":"","src":_vm.imgUrl},on:{"click":_vm.resetLoginForm}})],1),_c('a-form-model-item',{staticStyle:{"display":"inline","white-space":"nowrap"}},[_c('a-button',{staticStyle:{"width":"200px","margin-left":"50px","margin-top":"10px"},attrs:{"type":"primary","size":"large"},on:{"click":_vm.onSubmit}},[_vm._v("注 册 ")])],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }