<template>
  <div style="background-color: #f0f2f5; padding: 0px; height: 100% ;width: 100%">
    <div>
      <a-row :gutter="16">
        <a-col :span="6">
          <a-card title="账户概览" style="height: 18.5625rem" :bordered="true">
            <a-table :customRow="clickThisKey" :columns="accountOverview" :data-source="currentTimeMoneyCount"
                     size="small" :pagination="false">
              <!--                            <a slot="name" slot-scope="text">{{ text }}</a>-->
            </a-table>
          </a-card>
        </a-col>
        <a-col :span="6">
          <a-card style="height: 18.5625rem" title="分成费率" :bordered="true">
            <a-table :columns="rateColumns" :data-source="rateData" size="small" :pagination="false">
              <!--              <a slot="name" slot-scope="text">{{ text }}</a>-->
            </a-table>
          </a-card>
        </a-col>
        <a-col :span="6">
          <a-card style="height: 18.5625rem" title="异常订单" :bordered="true">
            <a-table :columns="abnormalOrder" :data-source="currentTimeAbnoramlCount" size="small" :pagination="false">
              <!--              <a slot="name" slot-scope="text">{{ text }}</a>-->
            </a-table>
          </a-card>
        </a-col>
        <a-col :span="6">
          <a-card  title="支付接口" :bordered="true">
            <a-table :columns="recharge" :data-source="rechargeData" size="small" :pagination="false">
              <span slot="rechargeUrl" slot-scope="url">
                <a-button @click="rechargeTheInterface(url)">下载</a-button>
              </span>
            </a-table>
          </a-card>
        </a-col>
      </a-row>
    </div>
    <div style="margin-top: 10px">
      <a-card title="充值趋势日统计" :bordered="true">
        <a-table :columns="today" :data-source="dataToday" size="small">
          <span slot-scope="time" slot="time">
           {{$moment(time).utcOffset( 480).format('yyyy-MM-DD HH:mm:ss')}}
        </span>
          <span slot="pay" slot-scope="pay">
            <span v-if="pay === 1">微信扫码</span>
            <span v-if="pay === 2">微信</span>
            <span v-if="pay === 3">支付宝扫码</span>
            <span v-if="pay === 4">支付宝</span>
          </span>
          <span slot="status" slot-scope="index, row">
          <span v-if="index === 0" style="color: #708090">待付款</span>
          <span v-else-if="index <= 1" style="color: red">已支付<a-button style="margin-left:5px;color: #001135"
                                                                       size="small" type="primary"
                                                                       @click="headNotify(row)">手动通知</a-button></span>
          <span v-else-if="index >= 2" style="color: #ADFF2F">已通知</span>
          <span v-else>出错啦!</span>
        </span>
        </a-table>
      </a-card>
    </div>
  </div>
</template>

<script>
import jwt from 'jsonwebtoken'
import API_CONFIG from "../../../vue.config"
const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    scopedSlots: {customRender: 'name'},
  },
  {
    title: 'Age',
    dataIndex: 'age',
    key: 'age',
    width: 80,
  },
];
/**
 * 今日充值订单信息
 * @type {*[]} 充值信息
 */
const today = [
  {
    title: '订单号',
    dataIndex: 'order_no',
    key: 'order_no'
  },
  {
    title: '玩家',
    dataIndex: 'player_name',
    key: 'player_name',

  },
  {
    title: '支付方式',
    dataIndex: 'pay_type',
    key: 'pay_type',
    scopedSlots: {customRender: 'pay'}
  },
  {
    title: '金额(元)',
    dataIndex: 'order_amount',
    key: 'order_amount'
  },
  {
    title: '创建时间',
    dataIndex: 'create_time',
    key: 'create_time',
    scopedSlots: {customRender: 'time'}
  },
  {
    title: '支付状态',
    dataIndex: 'order_status',
    key: 'order_status',
    scopedSlots: {customRender: 'status'}
  },
  {
    title: '分区名称',
    dataIndex: 'player_zone',
    key: 'player_zone'
  }
];
//账户概览
const accountOverview = [
  {
    title: '功能名称',
    dataIndex: 'name',
    key: 'name',
    scopedSlots: {customRender: 'name'},
  },
  {
    title: '交易金额',
    dataIndex: 'rate',
    key: 'rate',
  }
];
//异常订单
const abnormalOrder = [
  {
    title: '功能名称',
    key: 'name',
    dataIndex: 'name',
    scopedSlots: {customRender: 'name'},
  },
  {
    title: '订单数量',
    dataIndex: 'rate',
    key: 'rate'
  }
]
const rateColumns = [
  {
    title: '支付类型',
    dataIndex: 'name',
    key: 'name',
    scopedSlots: {customRender: 'name'},
  },
  {
    title: '分成',
    dataIndex: 'rate',
    key: 'rate',
    width: 80,
  },
];
const data = [
  {
    key: '1',
    name: 'John Brown',
  },
  {
    key: '2',
    name: 'Jim Green',
  },
  {
    key: '3',
    name: 'Joe Black',
  },
  {
    key: '4',
    name: 'Joe Black',
  },
];
const dataToday = [];
const recharge = [
  {
    title:'游戏名称',
    key:'id',
    dataIndex:'name',
  },
  {
    title:'接口下载',
    key:'id',
    dataIndex:'rechargeUrl',
    scopedSlots:{ customRender:'rechargeUrl'}
  }
];
const rechargeData = [
  {
    name:'西游H5',
    id:'7',
    rechargeUrl:'西游H5',
  },
  {
    name:'幽冥传奇自动传参',
    id:'2',
    rechargeUrl:'幽冥传奇自动传参',
  },
  {
    name:'战神引擎充值接口',
    id:'3',
    rechargeUrl:'战神引擎充值接口',
  },
  {
    name:'白娘子接口',
    id:'4',
    rechargeUrl:'白娘子接口',
  },
  {
    name:'雷霆H5',
    id:'5',
    rechargeUrl:'雷霆H5',
  },
  {
    name:'白日门',
    id:'6',
    rechargeUrl:'白日门',
  },
  {
    name:'演示例子',
    id:'1',
    rechargeUrl:'demo',
  }
];

export default {
  data() {
    return {
      data,
      columns,
      rateColumns,
      accountOverview,
      abnormalOrder,
      dataToday,
      today,
      amount: '',
      withdrawals: false,
      rateData: [],
      currentTimeMoneyCount: [],
      recharge,
      rechargeData,
      currentTimeAbnoramlCount: [],
      url:API_CONFIG.devServer.proxy["/platform"].target
    };
  },
  created() {
    this.orderOneMonth();
    // 获取利率
    this.getRate()
    // 日统计
    //this.queryOrder()
    //获取当前时间（已通知）订单的价钱总和
    this.getCurrentTimeMoneyCount()
    //获取当前订单异常订单的数量
    this.currentTimeAbonrmal()
    //获取一个月每天的订单数
    this.orderOneMonth()
    setTimeout(this.init, 500)
  },
  methods: {
    init() {
      // let myChart = this.$echarts.init(document.getElementById('chart'));
      // //获取保存在sessionStorage中的值
      // let showDatay = sessionStorage.getItem("month_Data");
      // let showDate = sessionStorage.getItem("month_Date");
      // if (showDatay !== null) {
      //   //把字符串重新分割成数组
      //   showDatay = showDatay.split(',');
      // }
      // if (showDate !== null) {
      //   //把字符串重新分割成数组
      //   showDate = showDate.split(',');
      // }
      // // 绘制图表
      // myChart.setOption({
      //   xAxis: {
      //     type: 'category',
      //     data: showDate
      //   },
      //   tooltip: {},
      //   yAxis: {
      //     type: 'value'
      //   },
      //   series: [{
      //     data: showDatay,
      //     type: 'line',
      //     smooth: true
      //   }]
      //   // tooltip: {},
      //   // xAxis: {
      //   //   data: showDate
      //   // },
      //   // yAxis: {},
      //   // series: [{
      //   //   name: '订单数',
      //   //   type: 'bar',
      //   //   data: showDatay
      //   // }]
      // });

    },

    // async queryOrder() {
    //   const {data: res} = await this.$http.post('/platform/order/list')
    //   if (res.code === 0) {
    //     console.log(res)
    //   }
    // },
    // 获取利率
    async getRate() {
      const {data: res} = await this.$http.post('/platform/rate/list')
      if (res.code === 0) {
        let acheData = [];
        for (let i = 0; i < 4; i++) {
          acheData[i] = {}
          acheData[i].key = i
          if (i == 0) {
            acheData[i].name = '微信扫码'
            acheData[i].rate = (100 - res.list.wx1) + '%'
          } else if (i == 1) {
            acheData[i].name = '微信H5'
            acheData[i].rate = (100 - res.list.wx2) + '%'
          } else if (i == 2) {
            acheData[i].name = '支付宝扫码'
            acheData[i].rate = (100 - res.list.ali3) + '%'
          } else if (i == 3) {
            acheData[i].name = '支付宝H5'
            acheData[i].rate = (100 - res.list.ali4) + '%'
          }
        }
        this.rateData = acheData;
      }
    },
    //获取当前充值
    async getCurrentTimeMoneyCount() {
      //获取到jwt加密的字符串
      let userId = sessionStorage.getItem('token');
      //进行解密jwt获取userId
      userId = jwt.decode(userId);
      //通过await http方式进行访问后端接口
      const {data: res} = await this.$http.post('/platform/order/orderQueryCurrentTimeMoneyCount/' + userId.userId)
      //通过auait http方式进行访问问后端接口
      const {data: des} = await this.$http.post('/platform/user/queryCurrentBalance');
      // const {data: settlementAmount} = await this.$http.post('/platform/settlement/settlementAmount/'+userId.userId);
      //创建一个空的数组对象
      let currentTimeMoneyCount = [];
      //判断响应是否成功
      if (res.code === 0) {
        //进行数组下标为0的地方进行初始化
        currentTimeMoneyCount[0] = {}
        //数组下标为零取名进行赋值Key = 1
        currentTimeMoneyCount[0].key = 1;
        //数组下标为零取名进行赋值name = 今日充值
        currentTimeMoneyCount[0].name = '今日充值';
        //判断当前是否有订单金额
        if (res.countMoney !== null) {
          //如果有信息直接进行赋值
          currentTimeMoneyCount[0].rate = res.countMoney;
        } else {
          //没有金额进行辅助为0
          currentTimeMoneyCount[0].rate = '0';
        }

      }
      //判断响应是否成功
      if (des.code === 0) {
        currentTimeMoneyCount[1] = {};
        currentTimeMoneyCount[1].key = 2;
        currentTimeMoneyCount[1].name = '账户余额';
        //判断响应的值是否为空
        if (des.currentBalance != null) {
          //如果有直接进行赋值
          currentTimeMoneyCount[1].rate = des.currentBalance;
        } else {
          currentTimeMoneyCount[1].rate = '0';
        }
      }
      // if (settlementAmount.code === 0){
      //   currentTimeMoneyCount[2] = {};
      //   currentTimeMoneyCount[2].key = 3;
      //   currentTimeMoneyCount[2].name = '结算中的金额';
      //   if(settlementAmount.settlementAmount !== null){
      //     currentTimeMoneyCount[2].rate = '0';
      //   }else{
      //     currentTimeMoneyCount[2].rate = '0';
      //   }
      // }
      //添加到this.currentTimeMoneyCount
      this.currentTimeMoneyCount = currentTimeMoneyCount;
    },

    //当前异常订单数量
    async currentTimeAbonrmal() {
      let userId = sessionStorage.getItem('token');
      userId = jwt.decode(userId);
      const {data: res} = await this.$http.post('/platform/order/orderAbnoramlTime/' + userId.userId);
      let currentAbonrmal = [];
      currentAbonrmal[0] = {};
      currentAbonrmal[0].key = 1;
      currentAbonrmal[0].name = '今日订单异常';
      if (res.code === 0) {
        currentAbonrmal[0].rate = res.abnoramlCount;
      }
      this.currentTimeAbnoramlCount = currentAbonrmal;
    },
    //查询近三十天每天的订单数
    async orderOneMonth() {
      let userId = sessionStorage.getItem('token');
      userId = jwt.decode(userId);
      const {data: res} = await this.$http.post('/platform/order/orderOneMonth/' + userId.userId);
      if (res.code === 0) {
        let showDatay = [];
        let showDate = [];
        for (let i = 0; i < res.month.length; i++) {
          res.month[i].create_time = this.$moment(res.month[i].create_time).format('YYYY-MM-DD HH:mm:SS');
          console.log(res.month[i].create_time);
          showDatay[i] = res.month[i].order_count;
          showDate[i] = this.getTime(res.month[i].create_time)
        }
        console.log(res);
        //因为异步的原因外部调用会出现数据丢失，目前解决方法先转成字符串放到sessionStorage中
        sessionStorage.setItem("month_Data", showDatay.join(","))
        sessionStorage.setItem("month_Date", showDate.join(","))
      }
    },
    /* async mention(){
       if(this.amount < 200){
          this.$message.error("提现金额不能小于200");
       }else{
         const {data: res} = await this.$http.post('/platform/businessController/mentionMoney',{"money":this.amount,"currentTimeMoney":this.currentTimeMoneyCount[1].rate});
         if(res.code === 0){
           if(res.isMentionMoney === 1){
             this.withdrawals = false;
             this.getCurrentTimeMoneyCount();
             this.$message.success('提现成功');
           }else if (res.isMentionMoney === 2){
             this.$message.error('提现金额不能大于余额');
           }else{
             this.withdrawals = false;
             this.getCurrentTimeMoneyCount();
             this.$message.error('提现失败');
           }
       }

       // if(this.currentTimeMoneyCount[1].rate < this.amount ){
       //   console.log("a",this.amount)
       //   console.log("c",this.currentTimeMoneyCount[1].rate)
       //   console.log(this.amount >this.currentTimeMoneyCount[1].rate)
       //   this.$message.error("提现金额不能大于余额");
       // }
       if(this.amount >= 200 && this.amount <= this.currentTimeMoneyCount[1].rate){
         console.log(1);
         }
       }
     },*/
    //给a-table添加点击时间 key是获取点击的时候是那个对象
    clickThisKey(key) {
      return {
        on: {
          click: () => {
            //判断key值为1进行跳转每日充值 （因为没有直接进行跳转到订单查询界面）
            if (key.key === 1) {
              this.$router.push('/OrderQuery')
            }
            if (key.key === 2) {
              this.$router.push('/WithdrawMoeny')
            }
          }
        }
      }
    },
    getTime(time) {
      var date = new Date(time)
      var y = date.getFullYear()
      var m = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
      var d = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate())
      return y + '-' + m + '-' + d
    },
    /**
     * 获取今日订单
     * @returns {Promise<void>}
     */
    async toDayOrder() {
      const {data: res} = await this.$http.post('/platform/order/toDayOrder');
      if (res.code === 0) {
        this.dataToday = res.dataToDayOrder;
      }
      console.log(res)
    },
    async headNotify(row) {
      const {data: res} = await this.$http.post('/platform/notify/headNotify', {orderNo: row.orderNo})
      if (res.code == 0) {
        this.$message.success(res.message)
      } else {
        this.$message.error(res.message)
      }
    },
    rechargeTheInterface(name){
      let url = this.url + "recharge/"+name+".zip";
      console.log(name)
      window.location.href = url;
    }
  },
  mounted() {
    this.toDayOrder()
    this.init()
  }
};

</script>

<style scoped>
.ant-card-head {
  background-color: #1890ff !important;
}

</style>