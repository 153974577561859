<template>
  <div>
    <a-card title="个人信息">
      <a-card-grid style="width:15%;text-align:center;height: 40px" :hoverable="false">
        <p style="margin-top: -10px;font-size: 16px">登录账号:</p>
      </a-card-grid>
      <a-card-grid style="width:85%;;height: 40px">
        <p style="margin-top: -10px;font-size: 16px">{{ data.username }}</p>
      </a-card-grid>

      <a-card-grid style="width:15%;text-align:center;height: 40px" :hoverable="false">
        <p style="margin-top: -10px;font-size: 16px">商户ID号:</p>
      </a-card-grid>
      <a-card-grid style="width:85%;;height: 40px">
        <p style="margin-top: -10px;font-size: 16px">{{ data.id }}</p>
      </a-card-grid>

      <a-card-grid style="width:15%;text-align:center;height: 40px" :hoverable="false">
        <p style="margin-top: -10px;font-size: 16px">商户秘钥:</p>

      </a-card-grid>
      <a-card-grid style="width:85%;;height: 48px;padding-top: 11px">
        <span>{{ data.accessKey }}</span>
        <a-button type="primary" style="margin-left: 20px" @click="copyUrl()" class="copy-btn"
                  :data-clipboard-text="data.accessKey">复制秘钥
        </a-button>
        <a-button type="danger" style="margin-left: 20px" @click="rebuild">重新生成</a-button>
      </a-card-grid>

      <a-card-grid style="width:15%;text-align:center;height: 40px" :hoverable="false">
        <p style="margin-top: -10px;font-size: 16px">邮箱地址:</p>
      </a-card-grid>
      <a-card-grid style="width:85%;;height: 40px">
        <p v-if="isShow" style="margin-top: -10px;font-size: 16px">{{ data.userEmail }}</p>
        <a-input v-else v-model="setUser.userEmail" style="margin-top: -10px;font-size: 16px" :value="data.userEmail"></a-input>
      </a-card-grid>

      <a-card-grid style="width:15%;text-align:center;height: 40px" :hoverable="false">
        <p style="margin-top: -10px;font-size: 16px">真实姓名:</p>
      </a-card-grid>
      <a-card-grid style="width:85%;;height: 40px">
        <p v-if="isShow" style="margin-top: -10px;font-size: 16px">{{ data.realName }}</p>
        <a-input v-else v-model="setUser.realName" style="margin-top: -10px;font-size: 16px" :value="data.realName"></a-input>
      </a-card-grid>

<!--      <a-card-grid style="width:15%;text-align:center;height: 40px" :hoverable="false">-->
<!--        <p style="margin-top: -10px;font-size: 16px">开户银行:</p>-->
<!--      </a-card-grid>-->
<!--      <a-card-grid style="width:85%;;height: 40px">-->
<!--        <p v-if="isShow" style="margin-top: -10px;font-size: 16px">{{ data.userCardBankName }}</p>-->
<!--        <a-input v-else v-model="setUser.userCardBankName" style="margin-top: -10px;font-size: 16px" :value="data.userCardBankName"></a-input>-->
<!--      </a-card-grid>-->

      <a-card-grid style="width:15%;text-align:center;height: 40px" :hoverable="false">
        <p style="margin-top: -10px;font-size: 16px">支付宝:</p>
      </a-card-grid>
      <a-card-grid style="width:85%;;height: 40px">
        <p v-if="isShow" style="margin-top: -10px;font-size: 16px">{{ data.userCardNo }}</p>
        <a-input v-else v-model="setUser.userCardNo" style="margin-top: -10px;font-size: 16px" :value="data.userCardNo"></a-input>
      </a-card-grid>

      <a-card-grid style="width:15%;text-align:center;height: 40px" :hoverable="false">
        <p style="margin-top: -10px;font-size: 16px">微信/QQ:</p>
      </a-card-grid>
      <a-card-grid style="width:85%;;height: 40px">
        <p v-if="isShow" style="margin-top: -10px;font-size: 16px">{{ data.userQq }}</p>
        <a-input v-else v-model="setUser.userQq" style="margin-top: -10px;font-size: 16px" :value="data.userQq"></a-input>
      </a-card-grid>

      <a-card-grid style="width:15%;text-align:center;height: 40px" :hoverable="false">
        <p style="margin-top: -10px;font-size: 16px">手机号</p>
      </a-card-grid>
      <a-card-grid style="width:85%;;height: 40px">
        <p v-if="isShow" style="margin-top: -10px;font-size: 16px">{{ data.userPhone }}</p>
        <a-input v-else v-model="setUser.userPhone" style="margin-top: -10px;font-size: 16px" :value="data.userPhone"></a-input>
      </a-card-grid>
      <a-button v-if="isShow" type="primary" style="margin-left: 1rem" @click="isShow = false">修改</a-button>
      <span v-else>
        <a-button type="primary" style="margin-left: 1rem" @click="updateUser()">确认</a-button>
        <a-button type="primary" style="margin-left: 1rem" @click="isShow = true">取消</a-button>
      </span>
      <a-button type="primary" style="margin-left: 1rem" @click="handleUpdate">修改密码</a-button>

      <a-modal
          title="修改密码"
          :visible="visible"
          @ok="handleOk"
          @cancel="handleCancel">
          原密码:<input v-model="formPassword.pastPassword" style="width: 12rem;border: 1px slategrey solid" type="text"/>
          <br/>
          新密码:<input v-model="formPassword.newPassword" style="margin-top: 1rem; width: 12rem;border: 1px slategrey solid" type="text"/>
          <br/>
          确认密码:<input v-model="formPassword.fixPassword" style="margin-top: 1rem; width: 11rem;border: 1px slategrey solid" type="text"/>
      </a-modal>



    </a-card>
  </div>
</template>
<script>
import Clipboard from 'clipboard'

export default {
  data() {
    return {
      data: {
        userCode: '',
        userId: '',
        accessKey: '',
        createdTime: '',
        realName: '',
        userCardBankName: '',
        userCardName: '',
        userCardNo: '',
        userEmail: '',
        userPhone: '',
        userQq: '',
        username: '',
      },
      setUser:{
        realName: '',
        userEmail: '',
        userQq: '',
        userCardBankName: '',
        userCardNo: '',
        userPhone: '',
      },
      formPassword:{
        pastPassword:'',
        newPassword:'',
        fixPassword:'',
      },
      isShow:true,
      visible: false,
    }
  },
  created() {
    this.init()
  },
  methods: {
    // 初始化方法 获取用户信息
    async init() {
      const {data: res} = await this.$http.post('/platform/user/message')
      if (res.code === 0) {
        console.log(res)
        this.data = res.mes;
        this.setUser = this.data;
      }
    },
    copyUrl() {
      let _this = this;
      let clipboard = new Clipboard(".copy-btn"); // 这里括号里填写上面点击事件绑定的class名
      clipboard.on("success", e => {
        console.log(e)
        // 复制成功，提示根据自己项目实际使用的UI来写
        _this.$message.success("复制成功")
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", e => {
        // 不支持复制，提示根据自己项目实际使用的UI来写
        console.log(e)
        _this.$message.error("该浏览器不支持自动复制")
        // 释放内存
        clipboard.destroy();
      });
    },
    async updateKey() {
      // 更新签名
      const {data: res} = await this.$http.post('/platform/user/updateSign')
      if (res.code === 0) {
        this.$message.success('重新生成成功')
        //重新加载页面
        this.init()
      } else {
        this.$message.error('重新生成失败')
      }
    },
    // 重新生成方法
    rebuild() {
      this.$modal.confirm({
        title: '确定跟新秘钥吗?',
        content: '更新秘钥有可能会导部分订单通知不到,请谨慎!',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          this.updateKey()
        },
        onCancel() {
        },
      });
    },
    //修改用户个人信息
    async updateUser(){
      console.log(this.setUser);
      const {data:res}  = await this.$http.post('/platform/user/updateUser',this.setUser);
      if (res.code === 0){
        if (res.isUser === 1){
          this.$message.success("修改成功");
        }else{
          this.$message.error("修改失败");
        }
      }
      this.isShow = true;
    },
    handleCancel(){
      this.visible = false;
    },
    handleUpdate(){
      this.visible = true;
    },
    async handleOk(){
      if (this.formPassword.pastPassword === null || this.formPassword.pastPassword === ''){
        this.$message.error('输入为空');
        return;
      }
      if(this.formPassword.newPassword === null || this.formPassword.newPassword === ''){
        this.$message.error('新密码输入为空');
        return;
      }
      if (this.formPassword.fixPassword !== this.formPassword.newPassword){
        this.$message.error('输入密码不一致');
        return;
      }

      const {data:res}  = await this.$http.post('/platform/user/updatePassword',{"username":this.data.username,
      "pastPassword":this.formPassword.pastPassword,"newPassword":this.formPassword.newPassword});

      if (res.code === 0){
        if(res.isPassword === -1){
           this.$message.error('原密码有误请重新输入');
           return;
        }
        if (res.isPassword === 0){
          this.$message.error('修改失败');
          return;
        }
        this.$message.success('修改成功');
        this.visible = false;
        this.init();
      }

      console.log(this.formPassword);
    }
  }
}

</script>