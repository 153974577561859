<template>
  <div :class="bodyShow">
    <a-layout id="components-layout-demo-custom-trigger">
      <a-layout-sider v-model="collapsed" :trigger="null" collapsible>
        <!--      <div id="logoId" class="logo" style="font-size: 20px; padding-left: 0px;color: white"></div>-->
        <a-icon
            class="trigger"
            :type="collapsed ? 'left-square' : 'right-square'"
            @click="exchange()"
        />
        <a-menu theme="dark" mode="inline" :default-selected-keys="defaultSelectKeys">
          <a-menu-item v-for="meun in meuns" :key="meun.id" @click="routerPage(meun.menuAddr,meun.id)">
            <a-icon type="control"/>
            <span>{{ meun.menuName }}</span>
          </a-menu-item>
          <!--        <a-menu-item key="2" @click="routerPage(2)">-->
          <!--          <a-icon type="key"/>-->
          <!--          <span>商户密钥</span>-->
          <!--        </a-menu-item>-->
          <!--        <a-menu-item key="3" @click="routerPage(3)">-->
          <!--          <a-icon type="block"/>-->
          <!--          <span>比例模板</span>-->
          <!--        </a-menu-item>-->
          <!--        <a-menu-item key="4" @click="routerPage(4)">-->
          <!--          <a-icon type="deployment-unit"/>-->
          <!--          <span>分组列表</span>-->
          <!--        </a-menu-item>-->
          <!--        <a-menu-item key="5" @click="routerPage(5)">-->
          <!--          <a-icon type="fork"/>-->
          <!--          <span>分区列表</span>-->
          <!--        </a-menu-item>-->
          <!--        <a-menu-item key="6" @click="routerPage(6)">-->
          <!--          <a-icon type="ordered-list"/>-->
          <!--          <span>充值订单</span>-->
          <!--        </a-menu-item>-->
          <!--        <a-menu-item key="7" @click="routerPage(7)">-->
          <!--          <a-icon type="unordered-list"/>-->
          <!--          <span>模拟订单</span>-->
          <!--        </a-menu-item>-->
          <!--        <a-menu-item key="8" @click="routerPage(8)">-->
          <!--          <a-icon type="radius-setting"/>-->
          <!--          <span>定时任务</span>-->
          <!--        </a-menu-item>-->
          <!--        <a-menu-item key="9" @click="routerPage(9)">-->
          <!--          <a-icon type="bar-chart"/>-->
          <!--          <span>结算管理(管理员)</span>-->
          <!--        </a-menu-item>-->
          <!--        <a-menu-item key="10" @click="routerPage(10)">-->
          <!--          <a-icon type="dot-chart"/>-->
          <!--          <span>结算管理(用户)</span>-->
          <!--        </a-menu-item>-->
          <!--        <a-menu-item key="11" @click="routerPage(11)">-->
          <!--          <a-icon type="line-chart"/>-->
          <!--          <span>利率管理</span>-->
          <!--        </a-menu-item>-->
          <!--        <a-menu-item key="12" @click="routerPage(12)">-->
          <!--          <a-icon type="line-chart"/>-->
          <!--          <span>每日充值统计</span>-->
          <!--        </a-menu-item>-->
          <!--        <a-menu-item key="13" @click="routerPage(13)">-->
          <!--          <a-icon type="line-chart"/>-->
          <!--          <span>分组充值统计</span>-->
          <!--        </a-menu-item>-->
          <!--        <a-menu-item key="14" @click="routerPage(14)">-->
          <!--          <a-icon type="line-chart"/>-->
          <!--          <span>分区充值统计</span>-->
          <!--        </a-menu-item>-->
        </a-menu>
      </a-layout-sider>
      <a-layout>
        <a-layout-header style="background: #fff; padding: 0">
          <!--        <a-icon-->
          <!--            class="trigger"-->
          <!--            :type="collapsed ? 'menu-unfold' : 'menu-fold'"-->
          <!--            @click="exchange()"-->
          <!--        />-->
          <a-popover style="height: 48px; width: 150px; float: right;line-height: 48px;"
                     @mouseenter="changeGroundcoloe()"
                     id="pop_box" @mouseleave="unchangeGroundcoloe()">
            <template slot="content">
              <a-list item-layout="horizontal" :data-source="data">
                <a-list-item slot="renderItem" slot-scope="item">
                  <a-list-item-meta
                      description=""
                  >
                    <a slot="title" @click="routerOther(item.title)">{{ item.title }}</a>
                  </a-list-item-meta>
                </a-list-item>
              </a-list>
              <!--            <a-button type="link" >-->
              <!--              个人中心-->
              <!--            </a-button>-->
              <!--            <a-button type="link" >-->
              <!--              退出登录-->
              <!--            </a-button>-->
            </template>
            <img src="../assets/images/head.png" width="30px" height="27px">
            <span style="padding: 5px 0 0 5px">{{ username }}</span>
          </a-popover>
        </a-layout-header>
        <a-layout-content
            :style="{ margin: '1.5rem 1rem', background: '#fff', minHeight: '17.5rem' }"
        >
          <router-view></router-view>
        </a-layout-content>
      </a-layout>
    </a-layout>

  </div>
</template>
<script>
const data = [

  {
    title: '退出系统',
  },
];
export default {
  data() {
    return {
      collapsed: false,
      data,
      defaultSelectKeys: ["1"],
      username: this.getUsername(),
      meuns: this.getMeuns(),
      bodyShow:'',
    };
  },
  created() {
    let nvlIDd = window.sessionStorage.getItem("nvlId")
    console.log("nvlID" + nvlIDd)
    if (nvlIDd != null && nvlIDd !== '') {
      this.defaultSelectKeys[0] = this.stripscript(nvlIDd);
    }
  },
  methods: {
    // 路由的主方法
    routerPage(address, nvlid) {
      window.sessionStorage.setItem('nvlId', "['" + nvlid + "']")
      this.$router.push(address);
      // if (nvlid === 1) {
      //   window.sessionStorage.setItem("nvlId", "['1']")
      //   this.$router.push('/Console')
      // } else if (nvlid === 2) {
      //   window.sessionStorage.setItem("nvlId", "['2']")
      //   this.$router.push('/Users')
      // } else if (nvlid === 3) {
      //   window.sessionStorage.setItem("nvlId", "['3']")
      //   this.$router.push('/Template')
      // } else if (nvlid === 4) {
      //   window.sessionStorage.setItem("nvlId", "['4']")
      //   this.$router.push('/GroupList')
      // } else if (nvlid === 5) {
      //   window.sessionStorage.setItem("nvlId", "['5']")
      //   this.$router.push('/AreaList')
      // } else if (nvlid === 6) {
      //   window.sessionStorage.setItem("nvlId", "['6']")
      //   this.$router.push('/OrderQuery')
      // } else if (nvlid === 7) {
      //   window.sessionStorage.setItem("nvlId", "['7']")
      //   this.$router.push('/RoleRootAdmin')
      // } else if (nvlid === 8) {
      //   window.sessionStorage.setItem("nvlId", "['8']")
      //   this.$router.push('/Timing')
      // } else if (nvlid === 9) {
      //   window.sessionStorage.setItem("nvlId", "['9']")
      //   this.$router.push('/SettlementQueryAdmin')
      // } else if (nvlid === 10) {
      //   window.sessionStorage.setItem("nvlId", "['10']")
      //   this.$router.push('/SettlementQuery')
      // } else if (nvlid === 11) {
      //   window.sessionStorage.setItem("nvlId", "['11']")
      //   this.$router.push('/Rate')
      // } else if (nvlid === 12){
      //   window.sessionStorage.setItem("nvlId","['12']")
      //   this.$router.push('/RechargedAiley')
      // } else if (nvlid === 13){
      //   window.sessionStorage.setItem("nvlId","['13']")
      // } else if (nvlid === 14){
      //   window.sessionStorage.setItem("nvlId","['14']")
      //   this.$router.push('/PartitionRechargedAiley')
      // }
    },
    // 右上角弹出框的点击事件
    routerOther(title) {
      if (title === '个人中心') {
        window.sessionStorage.setItem("nvlId", "['2']")
        this.defaultSelectKeys = "['2']"
        this.$router.push('/Users')
      } else {
        this.$router.push('/')
        window.sessionStorage.clear()
      }
    },
    changeGroundcoloe() {
      var a = document.getElementById("pop_box")
      a.style = "height: 3rem; width: 6.5625rem; float: right;line-height: 3rem;background-color:#ececec"
    },
    unchangeGroundcoloe() {
      var a = document.getElementById("pop_box")
      a.style = "height: 3rem; width: 6.5625rem; float: right;line-height: 3rem;background-color:#ffffff"
    },
    // 改变导航栏状态
    exchange() {
      this.collapsed = !this.collapsed
      // if (this.collapsed) {
      //   document.getElementById('logoId').style.visibility = "hidden"
      // } else {
      //   document.getElementById('logoId').style.visibility = "visible"
      //   document.getElementById('logoId').innerText = ""
      // }
    },
    stripscript(s) {
      // eslint-disable-next-line no-control-regex
      var pattern = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？↵\r\n]")
      var rs = "";
      for (var i = 0; i < s.length; i++) {
        rs = rs + s.substr(i, 1).replace(pattern, '');
      }
      return rs;
    },
    //获取用户名
    getUsername() {
      return sessionStorage.getItem("username");
    },
    //获取菜单列表
    getMeuns() {
      let meuns = sessionStorage.getItem('meuns');
      meuns = JSON.parse(meuns);
      this.meuns = meuns;
    },
    isPhoneOrPc(){
      //获取浏览器型号用来判断手机还是pc
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      console.log(flag)
      //目前测试自己电脑这个是没有数据的
      if (flag === null){
        this.bodyShow = 'bodyShowOne';
      }else{
        this.bodyShow = 'bodyShow';
      }
      return flag;
    }
    //判断是否拥有管理员权限
    // isShow(){
    //   console.log(window.sessionStorage.getItem('menus'))
    //   let meuns = window.sessionStorage.getItem("menus");
    //   console.log(meuns[0].id);
    //   return false;
    // }
  },
  mounted() {
    this.isPhoneOrPc()
    this.getMeuns()
  }
};
</script>
<style>
.ant-layout-header {
  height: 3rem !important;
}

#components-layout-demo-custom-trigger {
  height: 100%;
}

#components-layout-demo-custom-trigger .trigger {
  font-size: 1.125rem;
  line-height: 3rem;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 2rem;
  background: rgba(255, 255, 255, 0.2);
  margin: 1rem;
}
.bodyShowOne{
  width: 100%;
  height: 100%;
}
.bodyShow{
  width: 120rem;
  height: 65rem;
}
</style>
