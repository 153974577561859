<template>
  <a-card title="用户余额提现申请">
    <a-table :data-source="data" :columns="columns" size="small" rowKey="id">
      <span slot="color" slot-scope="color">
        <span v-if="color === '审核通过'" style="color: chartreuse;">{{color}}</span>
        <span v-else-if="color === '审核未通过'" style="color: red">{{color}}</span>
        <span v-else-if="color === '等待审核'" style="color: #1d90c5">{{color}}</span>
      </span>
      <span slot="status" slot-scope="index">
        <span v-if="index.business_settle_status === 1 || index.business_settle_status === -1">已审核</span>
        <span v-else>
          <a-button type="primary" @click="updateUserBalance(index,1)">通过</a-button>
          <a-button style="margin-left: 0.4rem" type="primary" @click="updateUserBalance(index,-1)">退回</a-button>
        </span>
      </span>
      <span slot="time" slot-scope="time">{{$moment(time).format('YYYY-MM-DD HH:mm:SS')}}</span>
    </a-table>
  </a-card>
</template>
<script>
const columns = [
  {
    title:'提现日期',
    dataIndex:'create_time',
    key:'create_time',
    scopedSlots: {customRender: 'time'}
  },
  {
    title:'提现金额',
    dataIndex:'business_amount',
    key:'business_amount'
  },
  {
    title:'提现状态',
    dataIndex:'businessname',
    key:'businessname',
    scopedSlots:{customRender:'color'}
  },
  {
    title:'提现账号',
    dataIndex:'user_card_no',
    key:'user_card_no'
  },
  {
    title:'提现方式',
    dataIndex:'withdrawa_mehtod_name',
    key:'withdrawa_mehtod_name'
  },
  {
    title:'提现人',
    dataIndex:'username',
    key:'username'
  },
  {
    title:'审核',
    key:'id',
    scopedSlots: {customRender: 'status'},
  }
]
const data = [
  {
    business_amount:'',
    businessname:'',
    create_time:'',
    withdrawa_mehtod_account_number:'',
    withdrawa_mehtod_name:'',
    name:'',
    id:'',

  }
];
export default{
  data(){
    return {
      data,
      columns,
    }
  },methods:{
    async queryUserBalanceWithdrawaAll(){
      const {data:res} = await this.$http.post('/platform/businessController/userBalanceWithdrawaShow');
      if(res.code === 0){
        let money = '';
        console.log(res);

        for(let i = 0; i < res.businesses.length; i++){
          /**
           *   结算状态
           *   -1 提现申请退回 0 提现未审核 1 提现审核通过
           */
          money = res.businesses[i].business_amount + '';
          res.businesses[i].business_amount = money.substr(1);
          if(res.businesses[i].business_settle_status === 0){
            res.businesses[i].businessname = '等待审核';
          }else if(res.businesses[i].business_settle_status === 1){
            res.businesses[i].businessname = '审核通过';
          }else{
            res.businesses[i].businessname ='审核未通过';
          }
        }
      }
      this.data = res.businesses;
    },
    async updateUserBalance(datasour,status){
      console.log(datasour);
      if(status === 1){
        const {data:res} = await this.$http.post('/platform/AliPayController/wxOrAli',{"businessId":datasour.id,"status":status,"userId":datasour.user_id,"userMoney":datasour.business_amount
          ,"name":datasour.real_name,"identity":datasour.user_card_no,"accountNumber":datasour.withdrawa_mehtod_name,"businessNo":datasour.data_business_No});
        if (res.code === 0){
          if (res.isMoney === 1){
            this.$message.success('审核成功');
            this.queryUserBalanceWithdrawaAll();
          }else{
            this.$message.error('审核失败');
            this.queryUserBalanceWithdrawaAll();
          }
        }
      }
      if(status === -1){
        const {data:userBalance} = await this.$http.post('/platform/user/updateUserBalance',{"userId":datasour.user_id,"money":datasour.business_amount,"balance":datasour.user_balance,"businessId":datasour.id});
        if(userBalance.code === 0){
          if(userBalance.isMoney === 1){
            this.$message.success('回退成功');
            this.queryUserBalanceWithdrawaAll();
          }else{
            this.$message.error('回退失败');
            this.queryUserBalanceWithdrawaAll();
          }
        }
      }
    }
  },mounted(){
    this.queryUserBalanceWithdrawaAll();
  }
}
</script>