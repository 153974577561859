<template>
  <div>
    <a-card title="角色管理" :bordered="false" style="width: 100%" size="small">
          <a-button type="primary" @click="visible2=true">添加角色</a-button>
          <a-button type="primary" style="margin-left: 20px" @click="visible3=!visible3">设置角色</a-button>
          <a-table v-show="!visible3" bordered :data-source="dataSource" :columns="columns" rewkey="id">
        <span slot="action" slot-scope="text, record">
           <a-button type="primary" size="small" @click="edit(record)">修改</a-button>
           <a-popconfirm
               title="确定删除此角色?"
               ok-text="是"
               cancel-text="否"
               @confirm="confirm(record.id)"
               @cancel="cancel(record.id)"
           >
            <a-button type="danger" size="small">删除</a-button>
          </a-popconfirm>
          </span>
          </a-table>
      <a-modal v-model="visible" title="编辑角色权限" @ok="handleOk()" okText="确定修改" cancelText="取消">
        角色名称:
        <a-input style="width: 200px" v-model="roleName"></a-input>
        <div>
          <a-tree
              v-model="checkedKeys"
              style="margin-top: 10px"
              checkable
              :tree-data="treeData"
              @check="onSelect"
          >
            <span slot="title0010" style="color: #1890ff">sss</span>
          </a-tree>
        </div>

      </a-modal>
      <a-modal v-model="visible2" title="添加角色" @ok="addRole()" okText="确定添加" cancelText="取消">
        角色名称:
        <a-input style="width: 200px" v-model="roleName" placeholder="输入角色名称"></a-input>
      </a-modal>

        <a-table v-show="visible3" :data-source="dataSource3" :columns="columns3" bordered rewkey="id">
          <span slot="action3" slot-scope="text, record">
          <a-button type="primary" size="small" @click="confirm4(record)">修改角色</a-button>
           <a-popconfirm
               title="确定删除此用户?"
               ok-text="是"
               cancel-text="否"
               @confirm="confirm3(record)"
           >
            <a-button type="danger" size="small">删除</a-button>
          </a-popconfirm>
          </span>
        </a-table>
      <a-modal v-model="visible4" title="修改用户角色" @ok="updateUserRole()" okText="确定修改" cancelText="取消">
<!--        <a-radio-group v-model="radioValue" >-->
<!--          &lt;!&ndash;-->
<!--          <a-radio :style="radioStyle" v-for="(item) in dataSource" :value="item.id" :rowKey="item.id">-->
<!--            {{ item.roleName}}-->
<!--          </a-radio>&ndash;&gt;-->
<!--        </a-radio-group>-->
        用户角色名称:
        <select v-model="radioValue"  style="width: 12.5rem;border: 1px white solid">
          <option  v-for="item in options" :key="item.id">{{item.roleName}}</option>
        </select>



<!--        <div>-->
<!--&lt;!&ndash;          <a-tree&ndash;&gt;-->
<!--&lt;!&ndash;              v-model="checkedKeys"&ndash;&gt;-->
<!--&lt;!&ndash;              style="margin-top: 10px"&ndash;&gt;-->
<!--&lt;!&ndash;              checkable&ndash;&gt;-->
<!--&lt;!&ndash;              :tree-data="treeData"&ndash;&gt;-->
<!--&lt;!&ndash;              @check="onSelect"&ndash;&gt;-->
<!--&lt;!&ndash;          ></a-tree>&ndash;&gt;-->
<!--        </div>-->

      </a-modal>

    </a-card>
  </div>
</template>

<script>
const columns = [
  {
    title: 'id',
    dataIndex: 'id',
    width: '30%',
  },
  {
    title: '角色名称',
    dataIndex: 'roleName',
  },
  {
    title: '选项',
    dataIndex: 'operation',
    scopedSlots: {customRender: 'action'},
    width: '30%'
  },
]
const columns3 = [
  {
    title: '用户id',
    dataIndex: 'id',
    width: '30%',
  },
  {
    title: '账号',
    dataIndex: 'username',
  },
  {
    title: '角色名称',
    dataIndex: 'roleName',
    width: '30%'
  },
  {
    title: '操作',
    dataIndex: 'roleId',
    scopedSlots: {customRender: 'action3'},
    width: '30%'
  },
]
const options = [
  {
    id:'',
    roleName:''
  }
]

export default {
  data() {
    return {
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
      columns,
      columns3,
      dataSource: [],
      dataSource3: [],
      visible: false,
      // 所有的菜单列表
      treeData: [],
      // 选中的菜单
      defaultTreeData: [],
      // 角色名称
      roleName: '',
      visible2: false,
      // 控制
      visible3: false,
      //
      record: {},
      visible4: false,
      // 角色di
      id: '',
      // 选择的
      checkedKeys: [],
      radioValue: '',
      options,
    };
  },
  created() {
    this.getRoleList()
    this.getUsers()
  },
  methods: {
    async getRoleList() {
      const {data: res} = await this.$http.post('/platform/role/list')
      if (res.code === 0) {
        this.dataSource = res.list
      } else {
        this.$message.error('操作失败')
      }
    },
    // 获取菜单所有
    async getMenuList() {
      const {data: res} = await this.$http.post('/platform/menu/allList')
      if (res.code === 0) {
        this.treeData = res.list
        for (let i = 0; i < this.treeData.length; i++) {
          this.treeData[i].key = this.treeData[i].id
          this.treeData[i].title = this.treeData[i].menuName
        }
      } else {
        this.$message.error('操作失败')
      }
    },

    // 获取当前角色权限
    async getMenuListById(id) {
      const {data: res} = await this.$http.post('/platform/menu/list', {id: id})
      if (res.code === 0) {
        this.checkedKeys = res.list
        console.log(this.checkedKeys)
      } else {
        this.$message.error('操作失败')
      }
    },

    // 获取所有用户
    async getUsers() {
      const {data: res} = await this.$http.post('/platform/role/getUsers')
      if (res.code === 0) {
        this.dataSource3=res.list
        console.log(this.dataSource3)

      } else {
        this.$message.error('失败')
      }
    },
    edit(record) {
      this.visible = true
      this.roleName = record.roleName
      this.id = record.id
      this.getMenuList()
      // 获取当前角色的权限
      this.getMenuListById(record.id)
    },
    deleteRole(id) {
      console.log(id)
    },
    // 修改角色信息
    async handleOk() {
      console.log(this.checkedKeys)
      const {data: res} = await this.$http.post('/platform/role/update', {
        id: this.id,
        roleName: this.roleName,
        idList: this.checkedKeys
      })
      if (res.code === 0) {
        this.$message.success('操作成功')
      } else {
        this.$message.error('操作失败')
      }
    },
    // 添加角色信息
    async addRole() {
      if(this.roleName==''){
        this.$message.error("无效名称")
        return
      }
      const {data: res} = await this.$http.post('/platform/role/add', {
        roleName: this.roleName
      })
      if (res.code === 0) {
        this.$message.success('添加成功')
      } else {
        this.$message.error('操作失败')
      }
      this.getRoleList()
    },
    onSelect(selectedKeys) {
      this.checkedKeys = selectedKeys;
    },

    // 删除角色
    async confirm(id) {
      const {data: res} = await this.$http.post('/platform/role/del', {
        id: id
      })
      if (res.code === 0) {
        this.$message.success('已删除')
        this.getRoleList()
      } else {
        this.$message.error('操作失败')
      }
    },
    // 删除用户
    async confirm3(record) {
      const {data:res} = await this.$http.post('/platform/user/deleteUserRoleByUserId/'+record.id);
      if(res.code === 0){
        if(res.isRole === 1){
          this.$message.success("删除成功");
          this.getUsers();
        }else{
          this.$message.success('删除失败');
          this.getUsers();
        }
      }
    },
    cancel(id){
      console.log(id)
    },
    // 修改角色
    async confirm4(record) {
      const {data: res} = await this.$http.get('/platform/role/list');
      if(res.code === 0){
        this.visible4 = true;
        this.roleName = record.roleName
        console.log(record);
        this.id = record.id;
        this.options = res.list;
      }
      // this.visible4=true
      // this.record=record
    },
    // 更新用户角色
    async updateUserRole() {
      for(let i = 0; i < this.options.length; i++){
        console.log(this.options[i]);
        if(this.radioValue === this.options[i].roleName){
          this.radioValue = this.options[i].id;
        }
      }
      const {data: res} = await this.$http.post('/platform/role/updateUserRole', {
        id: this.id,
        roleId: this.radioValue
      })
      if (res.code === 0) {
        this.$message.success('更新成功')
        this.getUsers()
      } else {
        this.$message.error('操作失败')
      }
    },
  },
};
</script>
