<template>
  <div>
    <a-card title="用户结算管理">
        用户id:
        <a-input v-model="form.userId" style="width: 14rem"/>
        用户名称:
        <a-input v-model="form.username" style="width: 14rem"/>&nbsp;
        <a-button @click="queryUserByUser" type="primary" icon="search">
          查询
        </a-button>
      <a-table :columns="columns" :data-source="data" @change="handleChange" />
    </a-card>
  </div>
</template>
<script>
const data = [
    //{sunCount: 209, id: 1, setAmount: 26534.07684, sumAmount: 27229.23, username: "chuanqi"}
  {
    id:'',
    sunCount:'',
    setAmount: '',
    sumAmount: '',
    username: ''
  }
];

export default {
  data() {
    return {
      data,
      filteredInfo: null,
      sortedInfo: null,
      form:{
        userId:'',
        username:''
      }
    };
  },
  computed: {
    columns() {
      let { sortedInfo } = this;
      sortedInfo = sortedInfo || {};
      const columns = [
        {
          title: '用户id',
          dataIndex: 'id',
          key: 'id',
          sorter: (a, b) => a.id - b.id,
          sortOrder: sortedInfo.columnKey === 'id' && sortedInfo.order,
        },
        {
          title: '用户名称',
          dataIndex: 'username',
          key: 'username',
          sorter: (a, b) => a.username - b.username,
          sortOrder: sortedInfo.columnKey === 'username' && sortedInfo.order,
        },
        {
          title: '用户订单数',
          dataIndex: 'sunCount',
          key: 'sunCount',
          sorter: (a, b) => a.sunCount - b.sunCount,
          sortOrder: sortedInfo.columnKey === 'sunCount' && sortedInfo.order,
        },
        {
          title: '用户充值金额',
          dataIndex: 'sumAmount',
          key: 'sumAmount',
          sorter: (a, b) => a.sumAmount - b.sumAmount,
          sortOrder: sortedInfo.columnKey === 'sumAmount' && sortedInfo.order,
        },
        {
          title: '用户收入金额',
          dataIndex: 'setAmount',
          key: 'setAmount',
          sorter: (a, b) => a.setAmount - b.setAmount,
          sortOrder: sortedInfo.columnKey === 'setAmount' && sortedInfo.order,
        }
      ];
      return columns;
    },
  },
  methods: {
    handleChange(pagination, filters, sorter) {
      console.log('Various parameters', pagination, filters, sorter);
      this.filteredInfo = filters;
      this.sortedInfo = sorter;
    },
    clearFilters() {
      this.filteredInfo = null;
    },
    clearAll() {
      this.filteredInfo = null;
      this.sortedInfo = null;
    },
    setAgeSort() {
      this.sortedInfo = {
        order: 'descend',
        columnKey: 'age',
      };
    },
    onChangeDate(){},
    async queryAllUserSettlement(){
      const {data:res} = await this.$http.post('/platform/settlement/queryAllUserSettlement');
      if(res.code === 0){
        for(let i = 0; i <  res.maps.length; i++){
          res.maps[i].setAmount = parseFloat(res.maps[i].setAmount).toFixed(2);
          res.maps[i].sumAmount = parseFloat(res.maps[i].sumAmount).toFixed(2);
        }
        this.data = res.maps;
      }
    },
    async queryUserByUser(){
      const {data:res} = await this.$http.post('/platform/settlement/queryUserByUser',this.form);
      if (res.code === 0){
        for(let i = 0; i <  res.maps.length; i++){
          res.maps[i].setAmount = parseFloat(res.maps[i].setAmount).toFixed(2);
          res.maps[i].sumAmount = parseFloat(res.maps[i].sumAmount).toFixed(2);
        }
        this.data = res.maps;
      }
    }
  },
   mounted(){
    this.queryAllUserSettlement()
  }
};
</script>
<style scoped>
.table-operations {
  margin-bottom: 16px;
}

.table-operations > button {
  margin-right: 8px;
}
</style>