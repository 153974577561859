<template>
  <a-card :title="card_title">
    <form style="width: 40rem; height: 30rem">
      <div style="display: flex; ">
        <span  style="font-size: 1rem; text-align: center; white-space: nowrap;  width: 10%; height: 2rem;">{{zoneName}}</span>
        <a-input v-model="name" style="margin-left: 1rem; width: 70%; height: 2rem" type="text" :placeholder="'请输入'+zoneName"/>
        <span style="margin-left: 1rem; white-space: nowrap">当前充值分区:{{zoneName}}</span>
      </div>
      <div style="display: flex; margin-top: 1rem ">
        <span style="font-size: 1rem; text-align: center; white-space: nowrap; width: 10%; height: 2rem;">金额</span>
        <a-input v-model="money" style="margin-left: 1rem; width: 62%; height: 2rem" type="text"/>
        <span style="white-space: nowrap; margin-left: 1rem">游戏币比例:1:{{exchangeRate}}{{currencyName}}</span>
      </div>
      <div style="display: flex; margin-top: 1rem; margin-left: 2rem">
        <a-button type="primary" @click="submitData()">立即提交</a-button>
        <a-button style="margin-left: 1rem" type="primary" @click="exit()">返回列表</a-button>
      </div>
    </form>
    <a-modal v-model="visible" :closable="visible" :confirmLoading="false" okType="none" :title="message" @ok="visible = false">
        {{none}}
      <div slot="footer"></div>
    </a-modal>
  </a-card>
</template>
<script>
export default {
  data(){
    return{
      card_title:'',
      zoneName:'',
      exchangeRate:'',
      currencyName:'',
      name:'',
      visible:false,
      message:'',
      none:'',
      money:'',
      notifyUrl:'',
      zoneId:'',
      templateId:'',
    };
  },
  methods:{
    Obtain(){
      let record = this.$route.query.record;
      console.log(record)
      this.card_title = record.zoneName+"-模拟充值";
      this.zoneName = record.zoneName;
      this.notifyUrl = record.notifyUrl;
      this.exchangeRate = record.exchangeRate;
      this.currencyName = record.currencyName;
      this.zoneId = record.id;
      this.templateId = record.templateId;
    },
    exit(){
      this.$router.push('/AreaList');
    },
    async submitData(){
      if(this.name === "" || this.name === undefined || this.money === "" || this.money === undefined){
        this.$message.error("输入为空");
        return;
      }
     if(this.money <= 0){
       this.$message.error("输入金额有误请重新输入");
       return;
     }
      let url = this.notifyUrl;
      const {data:res} = await this.$http.post("/platform/zone/getPost",{"url":url,"name":this.name,"money":this.money,"zoneId":this.zoneId,"templateId":this.templateId,"zoneName":this.zoneName});
      if(res.isUrl !== "SUCCESS"){
        this.visible = true;
        this.message = "充值失败";
        this.none = res.isUrl;
        return;
      }
      this.visible = true;
      this.message = "充值成功";
      this.none = res.isUrl;
      return;
    }
  }
  ,mounted() {
    this.Obtain();
  }
}
</script>