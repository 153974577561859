<template>
  <div>
    <a-card title="利率管理" :bordered="false" style="width: 100%">
      <a-table :columns="columns" :data-source="data" :scroll="{ y: 350 }" size="small" rowKey="userId"
               ref="ruleForm">
        <template
            v-for="col in ['wx1', 'wx2', 'ali3','ali4']"
            :slot="col"
            slot-scope="text, record"
        >
          <div :key="col">
            <a-input
                v-if="record.editable"
                style="margin: -5px 0"
                :value="text"
                @change="e => handleChange(e.target.value, record.id, col)"
            />
            <template v-else>
              {{ text }}
            </template>
          </div>
        </template>

        <span slot="time" slot-scope="record">{{ $moment(record.createdTime).format('YYYY-MM-DD HH:mm:SS') }}</span>
        <span slot="updateTime"
              slot-scope="record">{{ $moment(record.updateTime).format('YYYY-MM-DD HH:mm:SS') }}</span>
        <span slot="action" slot-scope="record" style="width: 100px">
          <span v-if="record.editable">
          <a @click="() => save(record.userId)">保存</a>
          <a-popconfirm title="确认取消吗?" @confirm="() => cancel(record.id)" style="margin-left: 20px">
            <a>取消</a>
          </a-popconfirm>
        </span>
        <span v-else>
          <a-button @click="() => edit(record.id)" type="primary">编辑</a-button>
        </span>
    </span>
      </a-table>
    </a-card>
    <!--    创建模板弹窗-->
    <a-modal v-model="visible" title="创建定时任务" @ok="handleOk()" okText="确认" cancelText="取消"
    >
      <a-form-model :model="form" style="width: 230px">
        <a-form-model-item label="任务名称">
          <a-input v-model="form.timingName" placeholder="任务名称"/>
        </a-form-model-item>
        <a-form-model-item label="类名">
          <a-input v-model="form.beanName"/>
        </a-form-model-item>
        <a-form-model-item label="方法名">
          <a-input v-model="form.methodName"/>
        </a-form-model-item>
        <a-form-model-item label="参数">
          <a-input v-model="form.param"/>
        </a-form-model-item>
        <a-form-model-item label="规则">
          <a-input v-model="form.cron"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <!--    修改编辑-->

  </div>
</template>
<script>
const columns = [
  {
    title: '用户ID',
    dataIndex: 'userId',
    key: 'userId',
    width: '60px'
  },
  {
    title: '用户账号',
    dataIndex: 'username',
    key: 'username',

  },
  {
    title: '微信扫码',
    dataIndex: 'wx1',
    key: 'wx1',
    scopedSlots: {customRender: 'wx1'}
  },
  {
    title: '微信H5',
    dataIndex: 'wx2',
    key: 'wx2',
    scopedSlots: {customRender: 'wx2'}

  },
  {
    title: '支付宝扫码',
    dataIndex: 'ali3',
    key: 'ali3',
    scopedSlots: {customRender: 'ali3'}

  },
  {
    title: '支付宝H5',
    dataIndex: 'ali4',
    key: 'ali4',
    scopedSlots: {customRender: 'ali4'}

  },

  {
    title: '创建时间',
    // dataIndex: 'createdTime',
    // key: 'createdTime',
    width: '180px',
    scopedSlots: {
      customRender: 'time'
    }
  },
  {
    title: '操作',
    key: 'action',
    width: '180px',
    scopedSlots: {customRender: 'action'},
  },
];

export default {
  data() {
    return {
      data: [],
      cacheData: [],
      // 开关状态
      isChecked: true,
      columns,
      // 添加分区显示
      visible: false,
      // 模拟充值是否显示
      isInvest: false,
      // 修改编辑显示
      isEdit: false,

      editingId: '',
      form: {
        id: undefined,
        // 类名称
        beanName: 'settlementServiceImpl',
        //方法名称
        methodName: 'testwo',
        // 附带参数
        param: '123',
        // 规则
        cron: '*/30 * * * * ?',

        createTime: '',


        lastTime: '',

        flagInvalid: '',
        timingName: ''
      }
      ,
    }
        ;
  },
  methods: {
    // 初始化方法
    async init() {
      // 获取分区列表
      const {data: res} = await this.$http.post('/platform/rate/selectRate')
      if (res.code === 0) {
        console.log(res.list)
        this.data = res.list;
      }
    },
    handleChange(value, id, column) {
      const newData = [...this.data];
      const target = newData.filter(item => id === item.id)[0];
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },
    edit(id) {
      const newData = [...this.data];
      const target = newData.filter(item => id === item.id)[0];
      this.editingId = id;
      if (target) {
        target.editable = true;
        this.data = newData;
      }
    },
    cancel(id) {
      console.log(id)
      this.init();
    },


    // 编辑保存
    async save(userId) {
      const newData = [...this.data];
      console.log(userId)
      // const newCacheData = [...this.cacheData];
      const target = newData.filter(item => userId === item.userId)[0];
      // const targetCache = newCacheData.filter(item => id === item.id)[0];
      // if (target && targetCache) {
      //   delete target.editable;
      //   this.data = newData;
      //   Object.assign(targetCache, target);
      //   this.cacheData = newCacheData;
      // }
      let pattern = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/
      if (!(pattern.test(target.wx1) && pattern.test(target.wx2) && pattern.test(target.ali3) && pattern.test(target.ali4))) {
        this.$message.error('参数有误')
        return
      }
      const {data: res} = await this.$http.post('/platform/rate/update', target)
      if (res.code == 0) {
        this.$message.success('修改成功!')
        this.editingKey = ''
        this.editable = true
        // 刷新列表
        this.init();

      } else {
        this.$message.error(res.message)
      }

    },
    // 确认删除方法
    async confirm(param) {
      const {data: res} = await this.$http.post('/platform/timing/del', param)
      if (res.code === 0) {
        this.$message.success('删除成功!')
        // 刷新列表
        this.init();
      } else {
        this.$message.error('删除失败!')
      }
    },
    //取消删除
    canceldel() {
    },
    async onChange(val) {
      if (val.flagInvalid == 1) {
        // 开启
        val.flagInvalid = 0
      } else {
        val.flagInvalid = 1
        // 关闭
      }
      const {data: res} = await this.$http.post('/platform/timing/updateState', val)
      if (res.code === 0) {
        this.$message.success(res.message)
        // 刷新列表
      } else {
        this.$message.error(res.message)
        this.init();
      }

    }
  },
  mounted() {
    this.init()
  }
};
</script>
