<template>
  <div>
    <a-card title="模板列表" :bordered="false" style="width: 100%">
      <a-button type="primary" @click="visible=true">创建模板</a-button>
      <a-table :columns="columns" :data-source="data" :scroll="{ y: 350 }" size="small" rowKey="templateId"
               ref="ruleForm">
        <span slot="time" slot-scope="record">{{$moment(record.createdTime).format('YYYY-MM-DD HH:mm:SS')}}</span>
        <span slot="updateTime" slot-scope="record">{{$moment(record.updateTime).format('YYYY-MM-DD HH:mm:SS')}}</span>
        <span slot="action" slot-scope="record" style="width: 100px">
          <a-button size="small" @click="showEdit(record)">修改</a-button>
          <a-popconfirm
              title="确定删除此模板??"
              ok-text="是"
              cancel-text="否"
              @confirm="confirm(record.templateId)"
              @cancel="cancel(record.templateId)"
          >
            <a-button type="danger" size="small">删除</a-button>
          </a-popconfirm>
    </span>
      </a-table>
    </a-card>
    <!--    创建模板弹窗-->
    <a-modal v-model="visible" title="创建模板" @ok="handleOk()" okText="确认创建" cancelText="取消">

      <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol" style="width: 630px">
        <a-form-model-item label="模板名称" extra="无实际用途,便于自己区分,请勿重名">
          <a-input v-model="form.templateName" placeholder="无实际用途,便于自己区分,请勿重名"/>
        </a-form-model-item>
        <a-form-model-item label="用户标识" extra="默认为帐号，也可填角色名，或角色ID">
          <a-input v-model="form.userSign" placeholder="默认为帐号，也可填角色名，或角色ID"/>
        </a-form-model-item>
        <a-form-model-item label="标识提示" extra="此提示会在充值页面显示，提示玩家如何查看用户标识 ">
          <a-input v-model="form.inputTips" placeholder="如: 通过角色面板查看角色ID"/>
        </a-form-model-item>
        <a-form-model-item label="回调重试次数" extra="用户付款后，若通知失败，则会每隔一分钟重试一次">
          <a-input v-model="form.retryCount" placeholder="用户付款后，若通知失败，则会每隔一分钟重试一次"/>
        </a-form-model-item>
        <a-form-model-item label="金额类型">
          <a-radio-group v-model="form.isFixedAmount1" @change="typeOnChange()">
            <a-radio value="0">
              任意金额
            </a-radio>
            <a-radio value="1">
              固定金额
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="游戏币名称" extra="默认 元宝,也可以是钻石、点券等">
          <a-input v-model="form.currencyName" placeholder="默认 元宝,也可以是钻石、点券等"/>
        </a-form-model-item>
        <a-form-model-item label="游戏币比例" extra="默认 100 即充值1元为100游戏币">
          <a-input v-model="form.exchangeRate" placeholder="默认 100 即充值1元为100游戏币"/>
        </a-form-model-item>
        <a-form-model-item label="最低充值" extra="默认 10 即最低充值10元">
          <a-input v-model="form.minAmount" placeholder="默认 10 即最低充值10元"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <!--    修改编辑-->
    <a-modal v-model="isEdit" title="修改模板" @ok="isEditFun()" okText="确认修改" cancelText="取消">
      <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol" style="width: 630px">
        <a-form-model-item label="模板名称" extra="无实际用途,便于自己区分,请勿重名">
          <a-input v-model="form.templateName" placeholder="无实际用途,便于自己区分,请勿重名"/>
        </a-form-model-item>
        <a-form-model-item label="用户标识" extra="默认为帐号，也可填角色名，或角色ID">
          <a-input v-model="form.userSign" placeholder="默认为帐号，也可填角色名，或角色ID"/>
        </a-form-model-item>
        <a-form-model-item label="标识提示" extra="此提示会在充值页面显示，提示玩家如何查看用户标识 ">
          <a-input v-model="form.inputTips" placeholder="如: 通过角色面板查看角色ID"/>
        </a-form-model-item>
        <a-form-model-item label="回调重试次数" extra="用户付款后，若通知失败，则会每隔一分钟重试一次">
          <a-input v-model="form.retryCount" placeholder="用户付款后，若通知失败，则会每隔一分钟重试一次"/>
        </a-form-model-item>
        <a-form-model-item label="金额类型">
          <a-radio-group v-model="form.isFixedAmount1" @change="typeOnChange()">
            <a-radio value="0">
              任意金额
            </a-radio>
            <a-radio value="1">
              固定金额
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="游戏币名称" extra="默认 元宝,也可以是钻石、点券等">
          <a-input v-model="form.currencyName" placeholder="默认 元宝,也可以是钻石、点券等"/>
        </a-form-model-item>
        <a-form-model-item label="游戏币比例" extra="默认 100 即充值1元为100游戏币">
          <a-input v-model="form.exchangeRate" placeholder="默认 100 即充值1元为100游戏币"/>
        </a-form-model-item>
        <a-form-model-item label="最低充值" extra="默认 10 即最低充值10元">
          <a-input v-model="form.minAmount" placeholder="默认 10 即最低充值10元"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
const columns = [
  {
    title: 'ID',
    dataIndex: 'templateId',
    key: 'templateId',
    width: '60px'
  },
  {
    title: '模板名称',
    dataIndex: 'templateName',
    key: 'templateName',

  },
  {
    title: '玩家标识',
    dataIndex: 'userSign',
    key: 'userSign',
  },
  {
    title: '货币名称',
    dataIndex: 'currencyName',
    key: 'currencyName',
  },
  {
    title: '充值比例',
    dataIndex: 'exchangeRate',
    key: 'exchangeRate',
  },
  {
    title: '最低充值',
    dataIndex: 'retryCount',
    key: 'retryCount',
    width: '100px',
  },
  {
    title: '创建时间',
    // dataIndex: 'createdTime',
    // key: 'createdTime',
    width: '180px',
    scopedSlots: {
      customRender: 'time'
    }
  },
  {
    title: '最后修改时间',
    width: '180px',
    scopedSlots: {
      customRender: 'updateTime'
    }
  },
  {
    title: '操作',
    key: 'action',
    width: '150px',
    scopedSlots: {customRender: 'action'},
  },
];

export default {
  data() {
    return {
      data: [],
      columns,
      // 添加分区显示
      visible: false,
      // 模拟充值是否显示
      isInvest: false,
      // 修改编辑显示
      isEdit: false,

      id: '',
      labelCol: {span: 4},
      wrapperCol: {span: 14},
      form: {
        // 模板名称
        templateName: '我的模板',
        // 用户标识
        userSign: '账号',
        // 玩家提示
        inputTips: '',
        // 回调次数
        retryCount: 1,
        // 是否定额
        isFixedAmount: false,
        isFixedAmount1: "0",
        // 货币名称
        currencyName: "元宝",
        // 游戏币比例
        exchangeRate: 100,
        // 最小充值金额
        minAmount: 10,

        templateId:''
      },
      //表单初始备份
      formBackUp: {
        // 模板名称
        templateName: '我的模板',
        // 用户标识
        userSign: '账号',
        // 玩家提示
        inputTips: '',
        // 回调次数
        retryCount: 1,
        // 是否定额
        isFixedAmount: false,
        isFixedAmount1: "0",
        // 货币名称
        currencyName: "元宝",
        // 游戏币比例
        exchangeRate: 100,
        // 最小充值金额
        minAmount: 10,
      },
    };
  },
  methods: {
    // 初始化方法
    async init() {
      const {data: res} = await this.$http.post('/platform/template/list')
      if (res.code === 0) {
        this.data = res.list;
      }
    },

    //打开编辑页面
    showEdit(row) {
      this.form.templateName=row.templateName
      this.form.templateId=row.templateId
      this.form.userSign=row.userSign
      this.form.inputTips=row.inputTips
      this.form.retryCount=row.retryCount
      if(row.isFixedAmount){
        this.form.isFixedAmount1='1'
      }else {
        this.form.isFixedAmount1='0'
      }
      this.form.isFixedAmount=row.isFixedAmount
      this.form.currencyName=row.currencyName
      this.form.exchangeRate=row.exchangeRate
      this.form.minAmount=row.minAmount
      this.isEdit = true
    },

    // 创建模板
    async handleOk() {
      this.visible = false
      const {data: res} = await this.$http.post('/platform/template/add', this.form)
      if (res.code === 0) {
        this.$message.success('创建成功!')
        // 刷新列表
        this.init();
        // 重置各个参数
        this.form = this.formBackUp;
      } else {
        this.$message.error('创建失败!')
      }
    },
    // 模拟充值操作
    isInvestFun() {
      this.isInvest = false
    },
    // 编辑保存
    async isEditFun() {
      const {data: res} = await this.$http.post('/platform/template/update', this.form)
      if(res.code==0){
        this.$message.success('修改成功!')
        this.form=this.formBackUp;
        // 刷新列表
        this.init();

      }else {
        this.$message.error('修改失败!')
      }
      // 关闭对挂框
      this.isEdit = false
    },
    // 确认删除方法
    async confirm(id) {
      const {data: res} = await this.$http.post('/platform/template/del', {templateId: id})
      if (res.code === 0) {
        this.$message.success('删除成功!')
        // 刷新列表
        this.init();
      } else {
        this.$message.error('删除失败!')
      }
    },
    //取消删除
    cancel() {
    },
    // 单选框转Boolean方法
    typeOnChange() {
      if (this.form.isFixedAmount1 === '0') {
        this.form.isFixedAmount = false;
      } else {
        this.form.isFixedAmount = true;
      }
    }
  },
  mounted() {
    this.init()
  }
};
</script>
