<template>
  <div>
    <a-card title="分组列表" :bordered="false" style="width: 100%">
      <a-button type="primary" @click="visible=true">创建分组</a-button>
      <a-table :columns="columns" :data-source="data" :scroll="{ y: 350 }" size="small" rowKey="id"
               ref="ruleForm">
        <span slot="time" slot-scope="record">{{$moment(record.createdTime).format('YYYY-MM-DD HH:mm:SS')}}</span>
        <span slot="updateTime" slot-scope="record">{{$moment(record.updateTime).format('YYYY-MM-DD HH:mm:SS')}}</span>
        <span slot="action" slot-scope="record" style="width: 100px">
          <a-button size="small" @click="isInvestFun(record)">充值链接</a-button>
          <a-button size="small" @click="showEdit(record)">修改</a-button>
          <a-popconfirm
              title="确定删除此分组?"
              ok-text="是"
              cancel-text="否"
              @confirm="confirm(record.id)"
              @cancel="cancel(record.id)"
          >
            <a-button type="danger" size="small">删除</a-button>
          </a-popconfirm>
    </span>
      </a-table>
    </a-card>
    <!--    创建模板弹窗-->
    <a-modal v-model="visible" title="输入分组名称" @ok="handleOk()" okText="确认创建" cancelText="取消">
      <a-input placeholder="" allow-clear v-model="form.groupName" />
    </a-modal>

    <!--    修改编辑-->
    <a-modal v-model="isEdit" title="修改分组名称" @ok="isEditFun()" okText="确认修改" cancelText="取消">
      <a-input placeholder="" allow-clear v-model="form.groupName" />
    </a-modal>
  </div>
</template>
<script>
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: '60px'
  },
  {
    title: '分组名称',
    dataIndex: 'groupName',
    key: 'groupName',
    width: '60px'
  },

  {
    title: '创建时间',
    width: '180px',
    scopedSlots: {
      customRender: 'time'
    }
  },
  {
    title: '最后修改时间',
    width: '180px',
    scopedSlots: {
      customRender: 'updateTime'
    }
  },
  {
    title: '操作',
    key: 'action',
    width: '150px',
    scopedSlots: {customRender: 'action'},
  },
];

export default {
  data() {
    return {
      data: [],
      columns,
      // 添加分区显示
      visible: false,
      // 模拟充值是否显示
      isInvest: false,
      // 修改编辑显示
      isEdit: false,

      id: '',
      labelCol: {span: 4},
      wrapperCol: {span: 14},
      form: {
        id:'',
        groupName:'',
        createTime:'',
        updateTime:''
      },
    };
  },
  methods: {
    // 初始化方法
    async init() {
      const {data: res} = await this.$http.post('/platform/group/list')
      if (res.code === 0) {
        this.data = res.list;
      }
    },

    //打开编辑页面
    showEdit(row) {
      this.form.groupName=row.groupName
      this.form.id=row.id
      this.isEdit = true
    },

    // 创建分组
    async handleOk() {
      if(this.form.groupName===''||this.form.groupName===undefined){
        return this.$message.error("请输入有效的分组名称")
      }
      const {data: res} = await this.$http.post('/platform/group/add', this.form)
      if (res.code === 0) {
        this.$message.success('创建成功!')
        // 刷新列表
        this.init();
        // 重置各个参数
      } else {
        this.$message.error('创建失败!')
      }
      this.visible = false
    },
    // 充值链接
    async isInvestFun(record) {
      this.$message.loading("去充值页面",1)
      record.groupKey = record.groupKey.replace(/-/g,'');
      window.open('/platform/RechargeLink/accessAreaClothes/'+record.id+'/'+record.groupKey,'_blank')
      console.log(record);
      this.isInvest = true
    },
    // 编辑保存
    async isEditFun() {
      const {data: res} = await this.$http.post('/platform/group/update', this.form)
      if(res.code==0){
        this.$message.success('修改成功!')
        // 刷新列表
        this.init();
      }else {
        this.$message.error('修改失败!')
      }
      // 关闭对挂框
      this.isEdit = false
    },
    // 确认删除方法
    async confirm(id) {
      const {data: res} = await this.$http.post('/platform/group/del', {id: id})
      if (res.code === 0) {
        this.$message.success('删除成功!')
        // 刷新列表
        this.init();
      } else {
        this.$message.error('删除失败!')
      }
    },
    //取消删除
    cancel() {
    },
    // 单选框转Boolean方法
    typeOnChange() {
      if (this.form.isFixedAmount1 === '0') {
        this.form.isFixedAmount = false;
      } else {
        this.form.isFixedAmount = true;
      }
    }
  },
  mounted() {
    this.init()
  }
};
</script>
