<template>
  <div>
    <a-card title="模拟订单" :bordered="false" style="width: 100%">
      账号:
      <a-input v-model="form.player_name" style="width: 140px"/>
<!--      用户账号:-->
<!--      <a-input v-model="form.playerName" style="width: 140px"/>-->
      时间:
      <a-range-picker @change="onChangeDate"/>
      <a-button type="primary" style="float:right" @click="querySettlementBySettlement">查询</a-button>
      <a-table :columns="columns" :data-source="data" size="small" rowKey="id">
        <span slot="create_time" slot-scope="create_time">{{$moment(create_time).utcOffset(480).format('yyyy-MM-DD HH:mm:ss')}}</span>
      </a-table>
    </a-card>
  </div>
</template>
<script>
const columns = [
  {
    title: '订单编号',
    dataIndex: 'order_no',
    key: 'order_no',
    width: '200px'
  },
  {
    title: '所属分区',
    dataIndex: 'player_zone',
    key: 'player_zone',
    width: '150px'
  },

  {
    title: '账号',
    width: '80px',
    dataIndex: 'player_name',
    key: 'player_name',
  },
  {
    title: '交易金额(元)',
    width: '80px',
    dataIndex: 'order_amount',
  },
  {
    title: '交易时间',
    width: '280px',
    dataIndex: 'create_time',
    scopedSlots:{ customRender:'create_time'}
  },
];

export default {
  data() {
    return {
      data: [],
       columns,
      // 抽屉显示方式
      placement: 'bottom',

      id: '',
      form: {
        player_name: '',
        groupName: '',
        playerName: '',
        createTime: '',
        updateTime: '',
        statue: '-1',
        payType: '-1'
      },
      orderId: ''
    };
  },
  methods: {
    // 初始化方法
    async init() {
      const {data: res} = await this.$http.post('/platform/order/SimulateOrders')
      if (res.code === 0) {
        this.data = res.orders;
      }
    },
    onChangeDate(date, dateString) {
      let str = dateString.toString().split(',');
      this.form.createTime = str[0]
      this.form.updateTime = str[1]
    },
    async querySettlementBySettlement(){
      const {data: res} = await this.$http.post('/platform/order/SimulateOrdersByForm',this.form)
      if (res.code === 0) {
        this.data = res.orders;
      }
    }


  },
  mounted() {
    this.init()
  }
};
</script>
<style>
.ant-drawer-content-wrapper {
  height: 150px !important;
}

</style>
