<template>
  <div>
    <a-card title="分区列表" :bordered="false" style="width: 100%">
      <a-button type="primary" @click="visible=true">创建分区</a-button>
      <a-table :columns="columns" :data-source="data" :scroll="{ y: 350 }" size="small" rowKey="id"
               ref="ruleForm">
        <span slot="flagInvalid" slot-scope="flagInvalid">
      <a-tag
          :color="flagInvalid === 0 ? 'volcano' : flagInvalid===1 ? 'green' : 'geekblue'"
      >
        {{ flagInvalid === 0 ? '作废' : flagInvalid === 1 ? '开放' : '关闭' }}
      </a-tag>
    </span>
        <span slot="time" slot-scope="record">{{ $moment(record.createdTime).format('YYYY-MM-DD HH:mm:SS') }}</span>
        <span slot="updateTime"
              slot-scope="record">{{ $moment(record.updateTime).format('YYYY-MM-DD HH:mm:SS') }}</span>
        <span slot="action" slot-scope="record" style="width: 100px">
          <a-button size="small" @click="isInvestFun(record)">模拟充值</a-button>
          <a-button size="small" @click="showEdit(record)">修改</a-button>
          <a-popconfirm
              title="确定删除此分区?"
              ok-text="是"
              cancel-text="否"
              @confirm="confirm(record.id)"
              @cancel="cancel(record)"
          >
            <a-button type="danger" size="small">删除</a-button>
          </a-popconfirm>
    </span>
      </a-table>
    </a-card>
    <!--    创建模板弹窗-->
    <a-modal v-model="visible" title="创建分区" @ok="handleOk()" okText="确认创建分区" cancelText="取消"
    >
      <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol" style="width: 630px">
        <a-form-model-item label="分区名称">
          <a-input v-model="form.zoneName" placeholder="请输入分区名称"/>
        </a-form-model-item>
        <a-form-model-item label="选择分组">
          <a-checkbox-group v-model="form.groupIds">
            <a-checkbox name="yt" v-for="(item,key) in groupList" :key="key" :value="item.id">
              {{ item.groupName }}
            </a-checkbox>
          </a-checkbox-group>
        </a-form-model-item>
        <a-form-model-item label="选择模板">
          <a-select placeholder="请选择一个模板" :options="templateList" :key="id" v-model="form.templateId">

          </a-select>
        </a-form-model-item>
        <a-form-model-item label="通知地址">
          <a-input v-model="form.notifyUrl" placeholder="比如: http://www.xxx.com/pay.php"/>
        </a-form-model-item>
        <a-form-model-item label="附加参数">
          <a-textarea
              v-model="form.moreArgs"
              placeholder="系统会发送必带参数,也可补充.填写格式如: key1=value1&key2=value2&key3=value3"
              :auto-size="{ minRows: 3, maxRows: 5 }"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <!--    修改编辑-->
    <a-modal v-model="isEdit" title="修改分区" @ok="isEditFun()" okText="确认修改" cancelText="取消">
      <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol" style="width: 630px">
        <a-form-model-item label="分区名称">
          <a-input v-model="form.zoneName" placeholder="请输入分区名称"/>
        </a-form-model-item>
        <a-form-model-item label="选择分组">
          <a-checkbox-group v-model="form.groupIds">
            <a-checkbox name="yt" v-for="(item,key) in groupList" :key="key" :value="item.id">
              {{ item.groupName }}
            </a-checkbox>
          </a-checkbox-group>
        </a-form-model-item>
        <a-form-model-item label="选择模板">
          <a-select placeholder="请选择一个模板" :options="templateList" :key="id" v-model="form.templateId">

          </a-select>
        </a-form-model-item>
        <a-form-model-item label="通知地址">
          <a-input v-model="form.notifyUrl" placeholder="比如: http://www.xxx.com/pay.php"/>
        </a-form-model-item>
        <a-form-model-item label="附加参数">
          <a-textarea
              v-model="form.moreArgs"
              placeholder="系统会发送必带参数,也可补充.填写格式如: key1=value1&key2=value2&key3=value3"
              :auto-size="{ minRows: 3, maxRows: 5 }"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: '60px'
  },
  {
    title: '分区名称',
    dataIndex: 'zoneName',
    key: 'zoneName',

  },
  {
    title: '使用模板',
    dataIndex: 'templateName',
    key: 'templateName',
  },
  {
    title: '货币名称',
    dataIndex: 'currencyName',
    key: 'currencyName',
  },
  {
    title: '充值比例',
    dataIndex: 'exchangeRate',
    key: 'exchangeRate',
  },

  {
    title: '创建时间',
    // dataIndex: 'createdTime',
    // key: 'createdTime',
    width: '180px',
    scopedSlots: {
      customRender: 'time'
    }
  },
  {
    title: '分区状态',
    width: '180px',
    dataIndex: 'flagInvalid',
    key: 'flagInvalid',
    scopedSlots: {customRender: 'flagInvalid'},
  },
  {
    title: '操作',
    key: 'action',
    width: '200px',
    scopedSlots: {customRender: 'action'},
  },
];

export default {
  data() {
    return {
      data: [],
      columns,
      // 添加分区显示
      visible: false,
      // 模拟充值是否显示
      isInvest: false,
      // 修改编辑显示
      isEdit: false,

      id: '',
      labelCol: {span: 4},
      wrapperCol: {span: 14},
      // 分组列表
      groupList: [],
      // 模板列表
      templateList: [],
      form: {
        id: undefined,
        // 模板名称
        zoneName: '',
        //通知地址
        notifyUrl: '',
        // 附带参数
        moreArgs: '',

        templateId: undefined,
        // 选中的groups
        defaultGroups: undefined,

        templateChangeId: 0,

        groupIds: [],
      },
      // 备份原始状态
      formBackUp: {
        id: undefined,
        // 模板名称
        zoneName: '',
        //通知地址
        notifyUrl: '',
        // 附带参数
        moreArgs: '',

        templateId: undefined,
        // 选中的groups
        defaultGroups: undefined,

        templateChangeId: 0,

        groupIds: [],
      }

    };
  },
  methods: {
    // 初始化方法
    async init() {
      // 获取分区列表
      const {data: res} = await this.$http.post('/platform/zone/list')
      if (res.code === 0) {
        this.data = res.list;
      }
      // 获取分组列表
      const {data: groupRes} = await this.$http.post('/platform/group/list')
      if (groupRes.code === 0) {
        this.groupList = groupRes.list;
      }

      // 获取模板列表
      const {data: templateRes} = await this.$http.post('/platform/template/list')
      if (templateRes.code === 0) {
        let tes = templateRes.list
        let tList = new Array(tes.length);
        for (let i = 0; i < tes.length; i++) {
          tList[i] = {};
          tList[i].label = tes[i].templateName;
          tList[i].value = tes[i].templateId;
        }
        this.templateList = tList;

      }
    },
    //打开编辑页面
    showEdit(row) {
      this.form.defaultGroups = row.groupIds
      this.form.notifyUrl = row.notifyUrl
      this.form.moreArgs = row.moreArgs
      this.form.groupIds = row.groupIds
      this.form.templateId = row.templateId
      this.form.id = row.id
      this.form.zoneName = row.zoneName

      this.isEdit = true
    },

    // 创建分区
    async handleOk() {
      if(this.form.zoneName===''||this.form.zoneName===undefined){
        return this.$message.error("请输入有效的分区名称")
      }
      if(this.form.templateId===''||this.form.templateId===undefined){
        return this.$message.error("必须选模板")
      }
      if(this.form.notifyUrl===''||this.form.notifyUrl===undefined){
        return this.$message.error("通知链接必填")
      }
      const {data: res} = await this.$http.post('/platform/zone/add', this.form)
      if (res.code === 0) {
        this.$message.success('创建成功!')
        // 刷新列表
        this.init();
        // 重置各个参数
        this.form=this.formBackUp;
        this.visible = false
      } else {
        this.$message.error('创建失败!')
      }

    },
    // 模拟充值操作
    isInvestFun(record) {
      console.log(record);
      this.$router.push({path:'/Analog',query:{record:record}});
      this.isInvest = false
    },
    // 编辑保存
    async isEditFun() {
      const {data: res} = await this.$http.post('/platform/zone/update', this.form)
      if (res.code == 0) {
        this.$message.success('修改成功!')
        this.form = this.formBackUp;
        // 刷新列表
        this.init();

      } else {
        this.$message.error('修改失败!')
      }
      // 关闭对挂框
      this.isEdit = false
    },
    // 确认删除方法
    async confirm(id) {
      const {data: res} = await this.$http.post('/platform/zone/del', {id: id})
      if (res.code === 0) {
        this.$message.success('删除成功!')
        // 刷新列表
        this.init();
      } else {
        this.$message.error('删除失败!')
      }
    },
    //取消删除
    cancel() {
    },
  },
  mounted() {
    this.init()
  }
};
</script>
