<template>
  <div>
    <a-card title="分区统计">
      <a-form-model :from="form" :label-col="{ span: 2 }" >
        请选择开始和结束时间:
        <a-range-picker @change="onChangeDate"/>
        &nbsp;<a-button type="primary" @click="statistics">
        统计
      </a-button>
      </a-form-model>
      <a-table style="margin-top: 20px;" :columns="columns" :data-source="data" @change="handleChange" />
    </a-card>
  </div>
</template>
<script>
import moment from 'moment';
import jwt from 'jsonwebtoken';
const data = [
  {
    key: '',
    order_amount: '',
    create_time: '',
    zone_id: '',
    zone_name:'',
    oredercount:'',
    percentage:'',
  },
];
export default {
  data() {
    return {
      dateFormat: 'YYYY/MM/DD',
      data,
      filteredInfo: null,
      sortedInfo: null,
      form:{
        currentTime:'',
        endTime:'',
      },
    };
  },
  methods: {
    handleChange(pagination, filters, sorter) {
      console.log('Various parameters', pagination, filters, sorter);
      this.filteredInfo = filters;
      this.sortedInfo = sorter;
    },
    //初始化去数据库进行查询
    async init(){
      //获取sessionStorage中的userId
      let user = sessionStorage.getItem('token');
      user = jwt.decode(user);
      const {data:res} = await this.$http.post('/platform/order/partitionStatistics/'+user.userId);
      console.log(res.maps);
      this.data = res.maps;
    },
    async statistics() {
      let user = sessionStorage.getItem('token');
      user = jwt.decode(user);
      console.log(this.form)
      const {data: res} = await this.$http.post('/platform/order/currentTimeAndEndTime/'+user.userId,this.form);
      console.log(res)
      this.data = res.currentTimeAndEndTime;
    },
    onChangeDate(date, dateString) {
      let str = dateString.toString().split(',');
      this.form.currentTime = str[0]
      this.form.endTime = str[1]
    },
    moment,
  },
  computed: {
    columns() {
      let { sortedInfo, filteredInfo } = this;
      sortedInfo = sortedInfo || {};
      filteredInfo = filteredInfo || {};
      const columns = [
        {
          title: '分区Id',
          dataIndex: 'zone_id',
          key: 'zone_id',
          filteredValue: filteredInfo.zone_id || null,
          onFilter: (value, record) => record.zone_id.includes(value),
          sorter: (a, b) => a.zone_id.length - b.zone_id.length,
          sortOrder: sortedInfo.columnKey === 'zone_id' && sortedInfo.order,
          ellipsis: true,
        },
        {
          title: '分区名称',
          dataIndex: 'zone_name',
          key: 'zone_name',
          sorter: (a, b) => a.zone_name - b.zone_name,
          sortOrder: sortedInfo.columnKey === 'zone_name' && sortedInfo.order,
        },
        {
          title: '充值笔数',
          dataIndex: 'oredercount',
          key: 'oredercount',
          filteredValue: filteredInfo.oredercount || null,
          onFilter: (value, record) => record.oredercount.includes(value),
          sorter: (a, b) => a.oredercount.length - b.oredercount.length,
          sortOrder: sortedInfo.columnKey === 'oredercount' && sortedInfo.order,
          ellipsis: true,
        },
        {
          title: '充值金额',
          dataIndex: 'order_amount',
          key: 'order_amount',
          filteredValue: filteredInfo.order_amount || null,
          onFilter: (value, record) => record.order_amount.includes(value),
          sorter: (a, b) => a.order_amount.length - b.order_amount.length,
          sortOrder: sortedInfo.columnKey === 'order_amount' && sortedInfo.order,
          ellipsis: true,
        },
        {
          title: '占比',
          dataIndex: 'percentage',
          key: 'percentage',
          filteredValue: filteredInfo.percentage || null,
          onFilter: (value, record) => record.percentage.includes(value),
          sorter: (a, b) => a.percentage.length - b.percentage.length,
          sortOrder: sortedInfo.columnKey === 'percentage' && sortedInfo.order,
          ellipsis: true,
        }
      ];
      return columns;
    },
  },mounted() {
    this.init()
  }
};
</script>
<style scoped>
.table-operations {
  margin-bottom: 16px;
}

.table-operations > button {
  margin-right: 8px;
}
</style>