<template>
  <a-card title="提现方式">
    <a-button class="editable-add-btn" @click="handleAdd">
      添加
    </a-button>
    <a-modal
        title="添加"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel">
    <table>
      <tr>
        <th>开户银行:</th>
        <th><input v-model="form.withdrawaMehtodName"/></th>
      </tr>
      <tr>
        <th>银行卡号:</th>
        <th><input v-model="form.withdrawaMehtodAccountNumber"/></th>
      </tr>
      <tr>
        <th>开户行:</th>
        <th><input v-model="form.accountBank"/></th>
      </tr>
      <tr>
        <th>真实姓名:</th>
        <th><input v-model="form.realName"/></th>
      </tr>
    </table>
      是否启用:
      <a-select v-model="form.withdrawaMehtodType"  style="width: 9rem;margin-top: 1rem" placeholder="请选择">
        <a-select-option value="-1">请选择</a-select-option>
        <a-select-option value="0">未启用</a-select-option>
        <a-select-option value="1">已启用</a-select-option>
      </a-select>
    </a-modal>
  <a-table :columns="columns" :data-source="data" bordered>
    <template
        v-for="col in ['withdrawaMehtodAccountNumber','defaultActiveFirstOption', 'withdrawaMehtodName','realName','accountBank']"
        :slot="col"
        slot-scope="text, record">
      <div :key="col">
        <a-input
            v-if="record.editable"
            style="margin: -5px 0"
            :value="text"
            @change="e => handleChange(e.target.value, record.key, col)"
        />
        <template v-else>
          <span v-if="text === '未启用'">
            <a-Switch :defaultChecked="record.defaultActiveFirstOption !== '未启用'" checkedChildren="开启" unCheckedChildren="关闭"  />
          </span>
          <span v-else-if="text === '已启用'">
            <a-Switch :defaultChecked="record.defaultActiveFirstOption === '已启用'" checkedChildren="开启" unCheckedChildren="关闭"  />
          </span>
          <span v-else>
            {{ text }}
          </span>
        </template>
        <span></span>
      </div>
    </template>
    <template slot="operation" slot-scope="text, record">
      <div class="editable-row-operations">
        <span v-if="record.editable">
          <a @click="() => save(record.key)">确定</a>
          <a-popconfirm title="确定要删除吗" @confirm="() => cancel(record.key)">
            <a>删除</a>
          </a-popconfirm>
        </span>
        <span v-else>
          <a :disabled="editingKey !== ''"  @click="edit(record.key)">修改</a>
        </span>
      </div>
    </template>

  </a-table>
  </a-card>
</template>
<script>
const columns = [
  {
    title: '提现账号',
    dataIndex: 'withdrawaMehtodAccountNumber',
    width: '25%',
    scopedSlots: { customRender: 'withdrawaMehtodAccountNumber' },
  },
  {
    title:'开户行',
    dataIndex: 'accountBank',
    width: '15%',
    scopedSlots: {customRender: 'accountBank'}
  },
  {
    title:'提现人姓名',
    dataIndex:'realName',
    width:'15%%',
    scopedSlots:{ customRender: 'realName'}
  }
  ,
  {
    title: '银行名称',
    dataIndex: 'withdrawaMehtodName',
    width: '15%',
    scopedSlots: { customRender: 'withdrawaMehtodName' },
  },
  {
    title: '是否启用',
    dataIndex: 'defaultActiveFirstOption',
    width: '15%',
    scopedSlots: { customRender: 'defaultActiveFirstOption' },
  },

  {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
  },
];
const data = [];
export default {
  data() {
    this.cacheData = data.map(item => ({ ...item }));
    return {
      data,
      visible:false,
      confirmLoading:false,
      columns,
      editingKey: '',
      form:{
        realName:'',
        withdrawaMehtodName:'',
        withdrawaMehtodAccountNumber:'',
        withdrawaMehtodType:'',
        accountBank:'',
      },
    };
  },
  methods: {
    edit(key){
      //获取当前所有内容
      const newData = [...this.data];
      //获取所选中的内容
      const target = newData.filter(item => key === item.key)[0];

      this.editingKey = key;
      if(target){
        target.editable = true;
        this.data = newData;
      }
    },
    handleChange(value, key, column) {
      const newData = [...this.data];
      const target = newData.filter(item => key === item.key)[0];
      console.log(value)
      console.log(column);
      console.log(newData)
      console.log(target)
      if (target) {
        target[column] = value;
        this.data = newData;
        console.log(target[column])
        console.log(this.data)
      }
    },
    //获取当前用户的提现账户信息
    async queryCurrentAccountNumber(){
      const {data:res} = await this.$http.post('/platform/withdrawaMenthod/queryWithodrawaMenthodByUserId');
      if(res.code === 0){
        for(let i = 0; i < res.dataWithdrawaMethod.length; i++){
          res.dataWithdrawaMethod[i].key = res.dataWithdrawaMethod[i].withdrawaMethodId;
          if (res.dataWithdrawaMethod[i].withdrawaMehtodType === 1){
            res.dataWithdrawaMethod[i].defaultActiveFirstOption = '已启用';
          }else{
            res.dataWithdrawaMethod[i].defaultActiveFirstOption = '未启用';
          }
        }
        this.data = res.dataWithdrawaMethod;
      }
      console.log(res)
    },
    async save(key){
      const newData = [...this.data];
      //这个不知道为什么获取不到值
      const newCacheData = [...this.cacheData];
      const target = newData.filter(item => key === item.key)[0];
      const targetCache = newData.filter(item => key === item.key)[0];
      console.log(newData)
      console.log(targetCache)
      if(targetCache.defaultActiveFirstOption === '已启用'){
        targetCache.withdrawaMehtodType = 1;
      }else if (targetCache.defaultActiveFirstOption === '未启用'){
        targetCache.withdrawaMehtodType = 0;
      }else if (targetCache.withdrawaMehtodName === '银行卡'){
        this.$message.error('抱歉银行卡暂未开发');
        this.queryCurrentAccountNumber();
        this.editingKey = '';
        return;
      }else{
        this.$message.error('修改失败请输入已启用或者未启用');
        this.queryCurrentAccountNumber();
        this.editingKey = '';
        return;
      }
      console.log(newCacheData)
      if (target && targetCache) {
        //根据用户id去进行修改
        const {data:res} = await this.$http.post('/platform/withdrawaMenthod/updateWithdrawaMenthodByUserId',targetCache);
        if(res.code === 0){
          if(res.isWithdrawaMenthod === 1){
            this.$message.success('修改成功');
            delete target.editable;
            this.data = newData;
            Object.assign(targetCache, target);
            this.cacheData = newCacheData;
            this.queryCurrentAccountNumber();
            this.editingKey = '';
          }else{
            this.editingKey = '';
            this.$router.push("/WithdrawalMethod")
            this.$message.error('修改失败');
          }
        }
      }
    },
    //删除操作
    async cancel(key){
      const {data:res} = await this.$http.post('/platform/withdrawaMenthod/deleteWithdrawaMenthodById',{"withdrawaMethodId":key});
      if(res.code === 0){
        if(res.isWithdrawaMenthod === 1){
          this.editingKey = '';
          this.queryCurrentAccountNumber();
           this.$message.success('删除成功');
        }else{
          this.editingKey = '';
          this.$router.push("/WithdrawalMethod")
          this.$message.error('删除失败');
        }
      }
      this.editingKey = '';
      console.log(key);
  },
    handleAdd(){
      console.log(this.visible);
      this.visible = true;
    },
    async handleOk(){
      if(this.form.withdrawaMehtodType === -1 || this.form.withdrawaMehtodType === ''){
        this.$message.error("请选择是否启用之后在进行添加");
        return;
      }
      if(this.form.withdrawaMehtodName === ''){
        this.$message.error('开户银行是否为空');
        return;
      }
      console.log(this.data)
      for(let i = 0; i < this.data.length; i++){
        if(this.form.withdrawaMehtodName === this.data[i].withdrawaMehtodName){
          this.$message.error('已添加此银行卡如果有误请修改请勿重复添加');
          return;
        }
      }

      if(this.form.withdrawaMehtodAccountNumber === ''){
        this.$message.error("请填写账号");
        return;
      }
      if (this.form.realName === ''){
        this.$message.error("请写填真实姓名")
        return;
      }
      if (this.form.accountBank === '' || this.form.accountBank === null) {
        this.$message.error('开户行不能为空');
        return;
      }
      if(this.form.withdrawaMehtodType > -1 && this.form.withdrawaMehtodName !== '' && this.form.withdrawaMehtodAccountNumber !== '' && this.form.realName !== ''){
        const {data: res} = await this.$http.post('/platform/withdrawaMenthod/insert',this.form);
        if(res.code === 0){
          if(res.isWithdrawaMenthod === 1){
            this.$message.success('添加成功');
            this.queryCurrentAccountNumber();
            this.visible = false;
          }else{
            this.$message.error("添加失败");
          }
        }
      }

    },
    handleCancel(){
      this.visible = false;
    }
  },
  mounted() {
    this.queryCurrentAccountNumber();
  }
};
</script>
<style scoped>
.editable-row-operations a {
  margin-right: 8px;
}
</style>
