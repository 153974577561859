<template>
  <div>
    <a-card title="每日充值统计">
      <a-form-model :from="form" :label-col="{ span: 2 }" >
        请选择开始和结束时间:
             <a-range-picker @change="onChangeDate"/>
        &nbsp;<a-button type="primary" @click="statistics">
        统计
      </a-button>
      </a-form-model>
      <a-table style="margin-top: 20px;" :columns="columns" :data-source="data" @change="handleChange" />
      <div>
        充值总金额:<a-input :disabled="isInput" :value="rechargeCount"  addonBefore="￥" addonAfter="RMB" type="info-circle" style="height: 0.2rem; color: rgba(0,0,0,.45); width: 16rem"/>
        收入总金额:<a-input :disabled="isInput" :value="rechargeMoney"  addonBefore="￥" addonAfter="RMB" type="info-circle" style="height: 0.2rem; color: rgba(0,0,0,.45); width: 16rem"/>
      </div>
    </a-card>
  </div>
</template>
<script>
import moment from 'moment';
const data = [
  {
    id:'',
    createTime: '',
    sunCount: '',
    sumAmount: '',
    setAmount:'',
  },
];
export default {
  data() {
    return {
      dateFormat: 'YYYY/MM/DD',
      data,
      filteredInfo: null,
      sortedInfo: null,
      form:{
        currentTime:'',
        endTime:'',
      },
      rechargeCount:'',
      rechargeMoney:'',
      isInput:true,
    };
  },
  methods: {
    handleChange(pagination, filters, sorter) {
      console.log('Various parameters', pagination, filters, sorter);
      this.filteredInfo = filters;
      this.sortedInfo = sorter;
    },
    //初始化去数据库进行查询
    async init(){
      const {data:res} = await this.$http.post('/platform/settlement/selectSettlementDailyCount');

      if (res.code === 0){
        //一共充值了多少金额
        let numbers = 0;
        let numberMoney = 0;
        for(let i = 0; i < res.list.length; i++){
          //进行计算总共充值了多少金额
          numbers += res.list[i].sumAmount;
          //进行计算总共收入多少
          numberMoney += res.list[i].setAmount;
        }
        this.rechargeMoney = numberMoney;
        this.rechargeCount = numbers;
        this.data = res.list;
      }
    },
    async statistics() {
      const {data: res} = await this.$http.post('/platform/settlement/selectSettlementDailyByTime',this.form);
      if (res.code === 0){
        //一共充值了多少金额
        let numbers = 0;
        let numberMoney = 0;
        for(let i = 0; i < res.list.length; i++){
          //进行计算总共充值了多少金额
          numbers += res.list[i].sumAmount;
          //进行计算总共收入多少
          numberMoney += res.list[i].setAmount;
        }
        this.rechargeMoney = numberMoney;
        this.rechargeCount = numbers;
        this.data = res.list;
      }
    },
    onChangeDate(date, dateString) {
      let str = dateString.toString().split(',');
      this.form.currentTime = str[0]
      this.form.endTime = str[1]
    },
   moment,
  },
  computed: {
    columns() {
      let { sortedInfo, filteredInfo } = this;
      sortedInfo = sortedInfo || {};
      filteredInfo = filteredInfo || {};
      const columns = [
        {
          title: '日期',
          dataIndex: 'createTime',
          key: 'createTime',
          filteredValue: filteredInfo.createTime || null,
          onFilter: (value, record) => record.createTime.includes(value),
          sorter: (a, b) => a.createTime.length - b.createTime.length,
          sortOrder: sortedInfo.columnKey === 'createTime' && sortedInfo.order,
          ellipsis: true,
        },
        {
          title: '充值笔数',
          dataIndex: 'sunCount',
          key: 'sunCount',
          sorter: (a, b) => a.sunCount - b.sunCount,
          sortOrder: sortedInfo.columnKey === 'sunCount' && sortedInfo.order,
        },
        {
          title: '充值金额',
          dataIndex: 'sumAmount',
          key: 'sumAmount',
          filteredValue: filteredInfo.sumAmount || null,
          onFilter: (value, record) => record.sumAmount.includes(value),
          sorter: (a, b) => a.sumAmount.length - b.sumAmount.length,
          sortOrder: sortedInfo.columnKey === 'sumAmount' && sortedInfo.order,
          ellipsis: true,
        },
        {
          title: '收入',
          dataIndex: 'setAmount',
          key: 'setAmount',
          filteredValue: filteredInfo.setAmount || null,
          onFilter: (value, record) => record.setAmount.includes(value),
          sorter: (a, b) => a.setAmount.length - b.setAmount.length,
          sortOrder: sortedInfo.columnKey === 'setAmount' && sortedInfo.order,
          ellipsis: true,
        }
      ];
      return columns;
    },
  },mounted() {
    this.init()
  }
};
</script>
<style scoped>
.table-operations {
  margin-bottom: 16px;
}

.table-operations > button {
  margin-right: 8px;
}
</style>