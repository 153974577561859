import Vue from 'vue'

import {Alert, Button} from 'ant-design-vue'
import {Form} from 'ant-design-vue'
import {Input} from 'ant-design-vue'
import {Checkbox} from 'ant-design-vue'
import {Select} from 'ant-design-vue'
import {Icon} from 'ant-design-vue'
import {Menu} from 'ant-design-vue'
import {Layout} from 'ant-design-vue'
import {Breadcrumb} from 'ant-design-vue'
import {Row} from 'ant-design-vue'
import {Card} from 'ant-design-vue'
import {Col} from 'ant-design-vue'
import {Table} from 'ant-design-vue'
import {Popover} from 'ant-design-vue'
import {List} from 'ant-design-vue'
import {Avatar} from 'ant-design-vue'
import {Divider} from 'ant-design-vue'
import {Tag} from 'ant-design-vue'
import {Modal} from 'ant-design-vue'
import {Popconfirm} from 'ant-design-vue'
import {FormModel} from 'ant-design-vue'
import {DatePicker} from 'ant-design-vue'
import {Switch} from 'ant-design-vue'
import {Tabs} from 'ant-design-vue'
import {Radio} from 'ant-design-vue'
import {message} from 'ant-design-vue'
import {Drawer} from 'ant-design-vue'
import {Carousel} from 'ant-design-vue'
import {Slider} from 'ant-design-vue'
import {Tree} from 'ant-design-vue'
import {TreeSelect} from 'ant-design-vue'
import moment from 'moment'


Vue.component(Button.name, Button)
Vue.component(Tabs.name, Tabs)
Vue.component(Tree.name, Tree)
Vue.component(TreeSelect.name, TreeSelect)
Vue.component(Form.name, Form)
Vue.component(Form.Item.name, Form)
Vue.component(Input.name, Input)
Vue.component(Input.TextArea.name, Input.TextArea)
Vue.component(Checkbox.name, Checkbox)
Vue.component(Checkbox.Group.name, Checkbox.Group)
Vue.component(Select.name, Select)
Vue.component(Select.Option.name, Select.Option)
Vue.component(Icon.name, Icon)
Vue.component(Menu.name, Menu)
Vue.component(Menu.Item.name, Menu.Item)
Vue.component(Menu.SubMenu.name, Menu.SubMenu)
Vue.component(Layout.name, Layout)
Vue.component(Layout.Content.name, Layout.Content)
Vue.component(Layout.Header.name, Layout.Header)
Vue.component(Layout.Sider.name, Layout.Sider)
Vue.component(Breadcrumb.name, Breadcrumb)
Vue.component(Breadcrumb.name, Breadcrumb)
Vue.component(Breadcrumb.Item.name, Breadcrumb.Item)
Vue.component(Row.name, Row)
Vue.component(Card.name, Card)
Vue.component(Card.Meta.name, Card.Meta)
Vue.component(Card.Grid.name, Card.Grid)
Vue.component(Col.name, Col)
Vue.component(Table.name, Table)
Vue.component(Popover.name, Popover)
Vue.component(List.name, List)
Vue.component(List.Item.name, List.Item)
Vue.component(List.Item.Meta.name, List.Item.Meta)
Vue.component(Avatar.name, Avatar)
Vue.component(Divider.name, Divider)
Vue.component(Tag.name, Tag)
Vue.component(DatePicker.RangePicker.name, DatePicker.RangePicker)
// Vue.component(Modal.name, Modal)
Vue.use(Modal)
Vue.component(Popconfirm.name, Popconfirm)
Vue.component(Alert.name, Alert)
Vue.component(FormModel.name, FormModel)
Vue.component(FormModel.Item.name, FormModel.Item)
Vue.component(DatePicker.name, DatePicker)
Vue.component(Switch.name, Switch)
Vue.component(Radio.name, Radio)
Vue.component(Drawer.name, Drawer)
Vue.component(Radio.Group.name, Radio.Group)
Vue.component(Carousel.name, Carousel)
Vue.component(Slider.name, Slider)
Vue.prototype.$message = message;
Vue.prototype.$modal = Modal;
Vue.prototype.$moment = moment


// export {
//     Affix,
//     Anchor,
//     AutoComplete,
//     Alert,
//     Avatar,
//     BackTop,
//     Badge,
//     Breadcrumb,
//     Button,
//     Calendar,
//     Card,
//     Collapse,
//     Comment,
//     Carousel,
//     Cascader,
//     Checkbox,
//     Col,
//     ConfigProvider,
//     DatePicker,
//     Divider,
//     Dropdown,
//     Empty,
//     Form,
//     FormModel,
//     Icon,
//     Input,
//     InputNumber,
//     Layout,
//     List,
//     LocaleProvider,
//     message,
//     Menu,
//     Mentions,
//     Modal,
//     notification,
//     Pagination,
//     Popconfirm,
//     Popover,
//     Progress,
//     Radio,
//     Rate,
//     Row,
//     Select,
//     Slider,
//     Spin,
//     Statistic,
//     Steps,
//     Switch,
//     Table,
//     Transfer,
//     Tree,
//     TreeSelect,
//     Tabs,
//     Tag,
//     TimePicker,
//     Timeline,
//     Tooltip,
//     Upload,
//     Drawer,
//     Skeleton,
//     Result,
//     Descriptions,
//     PageHeader,
// };
