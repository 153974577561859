import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/Login.vue'
import AdminLogin from '../components/AdminLogin.vue'
import Home from '../components/Home.vue'
import qs from 'qs'
import Users from '../components/user/Users.vue'
import Sys from '../components/sys/sys.vue'
import Console from '../components/console/Console.vue'
import AreaList from '../components/arealist/AreaList.vue'
import GroupList from '../components/grouplist/GroupList.vue'
import Template from '../components/template/Tempate.vue'
import OrderQuery from '../components/query/orderQuery.vue'
import Timing from '../components/timing/Timing.vue'
import Register from '../components/register/register.vue'
import SettlementQueryAdmin from '../components/query/settlementQueryAdmin.vue'
import SettlementQuery from '../components/query/settlementQuery.vue'
import Rate from '../components/rate/rate'
import RoleRootAdmin from '../components/role/RoleRootAdmin.vue'
import RechargedAiley from '../components/recharge/RechargedAiley'
import PartitionRechargedAiley from '../components/recharge/PartitionRechargedAiley.vue'
import GroupRechargedAiley from "../components/recharge/GroupRechargedAiley.vue"
import AdminSettlementQuery from "../components/query/AdminSettlementQuery.vue"
import QueryAllUserBalance from "../components/query/QueryAllUserBalance.vue";
import WithdrawalMethod from "../components/user/WithdrawalMethod"
import WithdrawMoeny from "../components/user/WithdrawMoney.vue"
import UserBalanceWithdrawaShow from '../components/user/UserBalanceWithdrawaShow'
import UserTimingWithdraw from "../components/user/UserTimingWithdraw.vue"
import Analog from "../components/arealist/Analog.vue"
import SimulatedOrders from "../components/arealist/SimulatedOrders.vue";
//import Rechargelink from "../components/recharge/Rechargelink"
//import text from "../text"
Vue.use(VueRouter)
Vue.use(qs)
const routes = [
    {
        path: '/',
        redirect: '/login',
        meta: {
            title: '旻泽'
        }
    },
    {
        path: '/login',
        component: Login,
        meta: {
            title: '旻泽'
        }
    },
    {
        path: '/home',
        component: Home,
        meta: {
            title: '标题'
        },
        redirect: '/Console',
        children: [
            {
                path: '/Users', component: Users,
            },
            {path: '/Sys', component: Sys},
            {path: '/Console', component: Console},
            {path: '/AreaList', component: AreaList},
            {path: '/GroupList', component: GroupList},
            {path: '/Template', component: Template},
            {path: '/OrderQuery', component: OrderQuery},
            {path: '/Timing', component: Timing},
            {path: '/SettlementQueryAdmin', component: SettlementQueryAdmin},
            {path: '/SettlementQuery', component: SettlementQuery},
            {path: '/Rate', component: Rate},
            {path: '/RechargedAiley', component: RechargedAiley},
            {path: '/RoleRootAdmin',component: RoleRootAdmin},
            {path: '/PartitionRechargedAiley' ,component: PartitionRechargedAiley},
            {path: '/GroupRechargedAiley' ,component: GroupRechargedAiley},
            {path: '/AdminSettlementQuery',component: AdminSettlementQuery},
            {path: '/QueryAllUserBalance',component: QueryAllUserBalance},
            {path: '/WithdrawalMethod',component: WithdrawalMethod},
            {path: '/WithdrawMoeny',component: WithdrawMoeny},
            {path: '/UserBalanceWithdrawaShow', component: UserBalanceWithdrawaShow},
            {path: '/UserTimingWithdraw',component: UserTimingWithdraw},
            {path: '/Analog' , component: Analog},
            {path: '/SimulatedOrders' , component: SimulatedOrders}
        ]
    },
    {path: '/Register', component: Register},
    {path: '/AdminLogin', component: AdminLogin},
  //  {path: '/text',component: text}
]
// 挂载路由导航守卫
const router = new VueRouter({
    // mode: 'history',
    routes: routes
})
router.beforeEach((to, from, next) => {
    // to 要访问的路径
    // from 代表从哪里来
    // next() 放行
    // next('/sf') 强制跳转
    if (to.meta.title) {
        document.title = to.meta.title
    }
    if (to.path === '/login') return next()
    if (to.path === '/Register') return next()
    if (to.path === '/RoleRootAdmin') return next()
    if(to.path === '/AdminLogin') return next()
    // 获取token
    const tokenStr = window.sessionStorage.getItem('token')
    if (!tokenStr) return next('/login')
    next()
})

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
export default router
