import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/ant'
import './assets/css/global.css'
import echarts from 'echarts'

Vue.prototype.$echarts = echarts
Vue.config.productionTip = false
import axios from 'axios'

import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

// 请求拦截器sessionStorage.set
axios.interceptors.request.use(config => {
    NProgress.start();
    // 请求头添加token
    config.headers.Authorization = window.sessionStorage.getItem('token')
    return config
})

// 响应拦截器
axios.interceptors.response.use(config => {
    NProgress.done();
    // 请求头添加token
    const {message: mes} = config.data
    if (mes === '无效签名' || mes === '登陆过期' || mes === 'token算法不一致' || mes === 'token无效') {
        // 1. 提示
        // config.fail('token失效')
        // 2. 删除本地token 和 user_id
        // this.$message.error(mes)
        if (window.sessionStorage.getItem('token') != undefined && window.sessionStorage.getItem('token') != '') {
            alert("登陆过期")
            window.sessionStorage.removeItem('token')
            // 3. 跳转 login
            router.push('/login')
        }
    }

    return config
})
// 允许携带cookie
axios.defaults.withCredentials = true
Vue.prototype.$http = axios
new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
