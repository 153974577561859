<template>
  <a-card title="自动提现设置">
    <a-table :data-source="data" :columns="columns">
      <span slot="timingWithdraw" slot-scope="onOroff">
        <span v-if="onOroff">已开启</span>
        <span v-else>已关闭</span>
      </span>
      <span slot="index" slot-scope="stop">
        <a-button @click="isShow(stop,data)" autoInsertSpaceInButton="true" type="primary">修改</a-button>
      </span>
    </a-table>
    <!--修改弹窗-->
    <a-modal title="修改" :visible="visible" @ok="updateUserTiming" @cancel="visible = false">
      <table >
        <tr>
          <th  style="float: right;">用户名:</th>
          <th>{{userExpand.username}}</th>
        </tr>
        <tr style="margin-top: 13rem">
          <th  style="padding-top: 1rem; float: right;">最小提现金额:</th>
          <th style="padding-top: 1rem">
            <input v-model="userExpand.minWithdrawMoney"  style="border: 1px slategrey solid" type="text"/>
          </th>
        </tr>
        <tr>
          <th  style=" padding-top: 1rem; float: right;">最大提现金额:</th>
          <th style="padding-top: 1rem">
            <input style="border: 1px slategrey solid" type="text" v-model="userExpand.maxWithdrawMoney"/>
          </th>
        </tr>
        <tr>
          <th style=" padding-top: 1rem; float: right;">定时提现:</th>
          <th style="padding-top: 1rem">
            <a-Switch v-model="userExpand.timingWithdraw" checkedChildren="开启" unCheckedChildren="关闭" :defaultChecked="userExpand.timingWithdraw" />
          </th>
        </tr>
      </table>
    </a-modal>
  </a-card>
</template>
<script>
const columns = [
  {
    title:'用户名',
    key:'username',
    dataIndex:'username',
    width:'15%'
  },
  {
    title:'定时提现',
    dataIndex: 'timingWithdraw',
    scopedSlots:{customRender:'timingWithdraw'},
    width:'15%'
  },
  {
    title:'最小提现金额',
    dataIndex:'minWithdrawMoney',
    width:'15%',
  },
  {
    title: '最大提现金额',
    dataIndex: 'maxWithdrawMoney',
    width:'15%',
  },
  {
    title:'操作',
    dataIndex: 'index',
    scopedSlots:{customRender:'index'},
    width:'15%',
  }
];
const data = [];
export default {
  data(){
    return {
      data,
      columns,
      visible:false,
      //存储要操作的信息
      userExpand:'',
      updateUserExpand: {
        maxWithdrawMoney:'',
        minWithdrawMoney:'',
        timingWithdraw:'',
      },
    }
  },
  methods:{
    //查询所有用户的定时提现信息
    async queryUserExpandAll(){
      const {data:res} = await this.$http.post('/platform/userExpandController/queryUserExpandAll');
      console.log(res)
      if (res.code === 0){
        //循环便利一下把一些输入给转换成对应的东西
        for(let i = 0; i < res.userExpands.length; i++){
          //进行判断用户定时操作是否执行
          if(res.userExpands[i].timingWithdraw === 0){
            res.userExpands[i].timingWithdraw = false;
          }else{
            res.userExpands[i].timingWithdraw = true;
          }
          res.userExpands[i].index = i;
        }
        this.data = res.userExpands;
      }
    },
    isShow(index,data){
      this.userExpand = data[index];
      this.visible = true;
    },
    async updateUserTiming(){
      const {data:res} = await this.$http.post('/platform/userExpandController/updateUserExpandByExpandIdAndUserId',this.userExpand);
      this.queryUserExpandAll();
      if(res.code === 0){
        if (res.isUpdateUserExpand === 1){
          this.$message.success('修改成功');
        }else{
          this.$message.error('修改失败');
        }
      }
      this.visible = false;
      return;
    }
  },
  mounted() {
    this.queryUserExpandAll()
  }
}
</script>