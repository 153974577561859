<template>
  <div>
    <a-card title="用户结算管理">
      用户id:
      <a-input style="width: 14rem"/>
      用户名称:
      <a-input style="width: 14rem"/>&nbsp;
      <a-button type="primary" icon="search">
        查询
      </a-button>
      <a-table :columns="columns" :data-source="data" @change="handleChange" />
    </a-card>
  </div>
</template>
<script>
const data = [
  {
    id:'',
    username:'',
    userBalance:''
  }
]
export default {
  data(){
    return {
      data,
    }
  },computed: {
    columns() {
      let { sortedInfo } = this;
      sortedInfo = sortedInfo || {};
      const columns = [
        {
          title: '用户id',
          dataIndex: 'id',
          key: 'id',
          sorter: (a, b) => a.id - b.id,
          sortOrder: sortedInfo.columnKey === 'id' && sortedInfo.order,
        },
        {
          title: '用户名称',
          dataIndex: 'username',
          key: 'username',
          sorter: (a, b) => a.username - b.username,
          sortOrder: sortedInfo.columnKey === 'username' && sortedInfo.order,
        },
        {
          title: '用户余额',
          dataIndex: 'userBalance',
          key: 'userBalance',
          sorter: (a, b) => a.userBalance - b.userBalance,
          sortOrder: sortedInfo.columnKey === 'userBalance' && sortedInfo.order,
        },
      ];
      return columns;
    },
  },
  methods: {
    handleChange(pagination, filters, sorter) {
      console.log('Various parameters', pagination, filters, sorter);
      this.filteredInfo = filters;
      this.sortedInfo = sorter;
    },
    clearFilters() {
      this.filteredInfo = null;
    },
    clearAll() {
      this.filteredInfo = null;
      this.sortedInfo = null;
    },
    setAgeSort() {
      this.sortedInfo = {
        order: 'descend',
        columnKey: 'age',
      };
    },
    onChangeDate(){},
    async queryAllUserSettlement(){
      const {data:res} = await this.$http.post('/platform/user/queryAllUserBalance');
      if(res.code === 0){
        console.log(res.maps)
        this.data = res.maps;
      }
      console.log(res);
    }
  },
  mounted(){
    this.queryAllUserSettlement()
  }
};
</script>
<style scoped>
.table-operations {
  margin-bottom: 16px;
}

.table-operations > button {
  margin-right: 8px;
}
</style>