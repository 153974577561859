<template>
  <div>
    <a-card title="结算管理" :bordered="false" style="width: 100%">
      订单号:
      <a-input v-model="form.settlementNo" style="width: 140px"/>
<!--      用户账号:-->
<!--      <a-input v-model="form.playerName" style="width: 140px"/>-->
      时间:
      <a-range-picker @change="onChangeDate"/>
      <a-select style="width: 100px" v-model="form.statue">
        <a-select-option value="-1">
          订单状态
        </a-select-option>
        <a-select-option value="0">
          未结算
        </a-select-option>
        <a-select-option value="1">
          手动结算
        </a-select-option>
        <a-select-option value="2">
          已结算
        </a-select-option>
      </a-select>


      <a-button type="primary" style="float:right" @click="querySettlementBySettlement">查询</a-button>
      <a-table :columns="columns" :data-source="data" size="small" rowKey="id"
      >


        <span slot="status" slot-scope="index">
          <span v-if="index === 0" style="color: coral">待结算</span>
          <span v-else-if="index === 1" style="color: #ADFF2F">已手动结算</span>
          <span v-else-if="index === 2" style="color: #1E90FF">已结算</span>
          <span v-else></span>
        </span>
      </a-table>
    </a-card>
  </div>
</template>
<script>
import jwt from 'jsonwebtoken'
const columns = [
  {
    title: '订单编号',
    dataIndex: 'settlementNo',
    key: 'settlementNo',
    width: '200px'
  },
  {
    title: '用户名称',
    dataIndex: 'userId',
    key: 'userId',
    width: '150px'
  },

  {
    title: '总金额(元)',
    width: '80px',
    dataIndex: 'sumAmount',
    key: 'sumAmount',
  },
  {
    title: '结算金额(元)',
    width: '280px',
    dataIndex: 'setAmount',
  },
  {
    title: '结算状态',
    key: 'statue',
    width: '150px',
    dataIndex: 'statue',
    scopedSlots: {customRender: 'status'}
  },
];

export default {
  data() {
    return {
      data: [],
       columns,
      // 抽屉显示方式
      placement: 'bottom',

      id: '',
      form: {
        settlementNo: '',
        groupName: '',
        playerName: '',
        createTime: '',
        updateTime: '',
        statue: '-1',
        payType: '-1'
      },
      orderId: ''
    };
  },
  methods: {
    // 初始化方法
    async init() {
      let userId = sessionStorage.getItem('token');
      userId = jwt.decode(userId);
      userId = userId.userId;
      const {data: res} = await this.$http.post('/platform/settlement/querySettlementByUserId/'+userId)
      if (res.code === 0) {
        this.data = res.settlementMains;
      }
    },
    onChangeDate(date, dateString) {
      let str = dateString.toString().split(',');
      this.form.createTime = str[0]
      this.form.updateTime = str[1]
    },
    onClose() {
    },
    // 手动通知
    async headSet(row) {
      const {data: res} = await this.$http.post('/platform/settlement/updateHead', {
        settlementNo: row.settlementNo,
        id: row.id
      })
      if (res.code == 0) {
        this.$message.success(res.message)
        this.init()
      } else {
        this.$message.error(res.message)
      }
    },
    async querySettlementBySettlement(){
      const {data: res} = await this.$http.post('/platform/settlement/querySettlementBySettlement',this.form)
      if (res.code === 0) {
        this.data = res.settlementMains;
        console.log(res.settlementMains)
      }
    }


  },
  mounted() {
    this.init()
  }
};
</script>
<style>
.ant-drawer-content-wrapper {
  height: 150px !important;
}

</style>
