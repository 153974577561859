<template>
  <a-card title="提现">
  <a-form>
    提现金额:
    <a-input v-model="money" style="width: 14rem; height: 2rem" placeholder="最低提现200"/>
    <br/>
<!--    请选择提现到:-->
<!--    <a-select v-model="redioValue" placeholder="请选择" style="margin-top: 1rem; width: 120px">-->
<!--      <a-select-option  v-for="item in withdraw"  :key="item.withdrawaMethodId" :value="item.withdrawaMethodId" :disabled="item.defaultActiveFirstOption">-->
<!--        <option v-if="item.withdrawaMehtodType !== 0">{{item.withdrawaMehtodName}}</option>-->
<!--        <option v-else value="disabled" disabled>{{item.withdrawaMehtodName}}</option>-->
<!--      </a-select-option>-->
<!--    </a-select>-->
<!--    <br/>-->
    <a-button @click="withdrawMoney" type="primary">
      提现
    </a-button>
  </a-form>
    <template>
      <a-card style="margin-top: 1rem" title="提现详情信息">
        时间:<a-range-picker @change="onChangeDate" />
<!--        提现状态: <a-select  style="width: 12rem" v-model="this.from.is">-->
<!--          <a-select-option value="-1">提现失败</a-select-option>-->
<!--          <a-select-option value="0">提现审核中</a-select-option>-->
<!--          <a-select-option value="1">提现成功</a-select-option>-->
<!--        </a-select>-->
        <a-button @click="queryBusiness" style="margin-left: 1rem" type="primary">
          查询
        </a-button>
        <a-table :columns="columns" :data-source="data">
          <span slot="color" slot-scope="color">
            <span v-if="color === '提现成功'" style="color: green;">{{color}}</span>
            <span v-else-if="color === '提现审核中'" style="color: #1d90c5">{{color}}</span>
            <span v-else-if="color === '提现失败'" style="color: red">{{color}}</span>
          </span>
          <span slot="time" slot-scope="time">{{$moment(time).format('YYYY-MM-DD HH:mm:SS')}}</span>
        </a-table>
      </a-card>
    </template>
  </a-card>
</template>
<script>
const withdraw = []
const columns = [
  {
    title:'提现金额',
    dataIndex:'money',
    key:'money'
  },
  {
    title:'提现时间',
    dataIndex:'time',
    key:'time',
    scopedSlots: {customRender: 'time'}
  },
  {
    title:'提现方式',
    dataIndex:'accountname',
    key:'accountname'
  },
  {
    title:'提现账号',
    dataIndex:'username',
    key:'username'
  },
  {
    title:'提现状态',
    dataIndex:'isbalance',
    key:'isbalance',
    scopedSlots: {customRender: 'color'},
  }

]
const data = [
  {
    accountnumber:'',
    money:'',
    accountname:'',
    time:'',
    isbalance:''
  }
]
export default {
 data(){
   return{
     withdraw,
     redioValue:'1',
     money:'',
     columns,
     data,
     from:{
       createTime:'',
       endTime:'',
       is:''
     }
   }
 },
  methods:{
    //获取当前用户的提现方式
    async queryWithdrawMethod(){
      //获取提现方式
      const {data:res} = await this.$http.post('/platform/withdrawaMenthod/queryWithodrawaMenthodByUserId');
      if(res.code === 0){
        for(let i = 0; i < res.dataWithdrawaMethod.length; i++){
          if(res.dataWithdrawaMethod[i].withdrawaMehtodType === 1){
            res.dataWithdrawaMethod[i].defaultActiveFirstOption = false;
          }else{
            res.dataWithdrawaMethod[i].defaultActiveFirstOption = true;
          }
        }
        console.log(res.dataWithdrawaMethod)
        this.withdraw = res.dataWithdrawaMethod;
      }
    },
    async withdrawMoney(){
      if(this.money < 200){
        this.$message.error("提现金额不能小于200");
        return;
      }
      if(this.money >= 200){
        const {data:res} = await this.$http.post('/platform/user/withdrawMoney',{"money":this.money,"withdrawaMethodId":this.redioValue});
        if (res.code === 0){
          if(res.isMoney === 1){
            this.$message.success('提现成功');
            this.queryBusiness();
          }
          if(res.isMoney === -1){
            this.$message.error('提现金额不能大于余额');
          }
          if(res.isMoney === 0){
            this.$message.error('提现失败');
            this.queryBusiness();
          }
        }
      }
    },
    //获取当前用户提现信息
    async queryBusiness(){
      const {data:res} = await this.$http.post('/platform/businessController/queryBusinessByUserId',this.from);
      if(res.code === 0){
        let money = '';
        for(let i = 0; i < res.business.length; i++){
         money = res.business[i].money+'';
          //去除前面那个减号
          res.business[i].money = money.substr(1);
         /**
           *   -1 提现申请退回 0 提现未审核 1 提现审核通过
           */
          if(res.business[i].isuser === 1){
            res.business[i].isbalance = '提现成功';
          }else if (res.business[i].isuser === 0){
            res.business[i].isbalance = '提现审核中';
          }else {
            res.business[i].isbalance = '提现失败';
          }
        }
      }
      this.data = res.business;
    },
    onChangeDate(date, dateString) {
      let str = dateString.toString().split(',');
      this.from.createTime = str[0]
      this.from.endTime = str[1]
    }
  },
  mounted(){
    this.queryWithdrawMethod()
    this.queryBusiness()
  }
};
</script>