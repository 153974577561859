<template>
  <div>
    <a-card title="分组统计">
      <a-form-model :from="form" :label-col="{ span: 2 }" >
        请选择开始和结束时间:
        <a-range-picker @change="onChangeDate"/>
        &nbsp;<a-button type="primary" @click="getGroupTopUpStatisticsByTime">
        统计
      </a-button>
      </a-form-model>
      <a-table :columns="columns" :data-source="data" @change="handleChange" />
    </a-card>
  </div>
</template>
<script>
import moment from 'moment';
import jwt from 'jsonwebtoken';
const data = [
  {
    key: '',
    group_name: '',
    ordernumber: '',
    money: '',
    actualBenefits:'',
  },
];
export default {
  data() {
    return {
      dateFormat: 'YYYY/MM/DD',
      data,
      filteredInfo: null,
      sortedInfo: null,
      form:{
        currentTime:'',
        endTime:'',
      },
    };
  },
  methods: {
    handleChange(pagination, filters, sorter) {
      console.log('Various parameters', pagination, filters, sorter);
      this.filteredInfo = filters;
      this.sortedInfo = sorter;
    },
    moment,
    //获取分组
    async getGroupTopUpStatistics(){
      //获取userId
      let userId = sessionStorage.getItem('token')
      userId = jwt.decode(userId);
      userId = userId.userId;
      let {data:res} = await this.$http.post('/platform/group/groupTopUpStatistics/'+userId);
      this.data =res.groupTopUpStatistics;
    },
    //根据日期去查询分组
    async getGroupTopUpStatisticsByTime(){
      let userId = sessionStorage.getItem('token')
      userId = jwt.decode(userId);
      userId = userId.userId;
      let {data:res} = await this.$http.post('/platform/group/groupTopUpStatisticsByTime/'+userId,this.form);
      this.data = res.groupTopUpStatistics
    },
    onChangeDate(date, dateString) {
      let str = dateString.toString().split(',');
      this.form.currentTime = str[0]
      this.form.endTime = str[1]
    }
  },
  computed: {
    columns() {
      let { sortedInfo, filteredInfo } = this;
      sortedInfo = sortedInfo || {};
      filteredInfo = filteredInfo || {};
      const columns = [
        {
          title: '分组名称',
          dataIndex: 'group_name',
          key: 'group_name',
          filteredValue: filteredInfo.group_name || null,
          onFilter: (value, record) => record.group_name.includes(value),
          sorter: (a, b) => a.group_name.length - b.group_name.length,
          sortOrder: sortedInfo.columnKey === 'group_name' && sortedInfo.order,
          ellipsis: true,
        },
        {
          title: '充值笔数',
          dataIndex: 'ordernumber',
          key: 'ordernumber',
          sorter: (a, b) => a.ordernumber - b.ordernumber,
          sortOrder: sortedInfo.columnKey === 'ordernumber' && sortedInfo.order,
        },
        {
          title: '充值金额',
          dataIndex: 'money',
          key: 'money',
          filteredValue: filteredInfo.money || null,
          onFilter: (value, record) => record.money.includes(value),
          sorter: (a, b) => a.money.length - b.money.length,
          sortOrder: sortedInfo.columnKey === 'money' && sortedInfo.order,
          ellipsis: true,
        },
        {
          title: '收入',
          dataIndex: 'actualBenefits',
          key: 'actualBenefits',
          filteredValue: filteredInfo.actualBenefits || null,
          onFilter: (value, record) => record.actualBenefits.includes(value),
          sorter: (a, b) => a.address.actualBenefits - b.actualBenefits.length,
          sortOrder: sortedInfo.columnKey === 'actualBenefits' && sortedInfo.order,
          ellipsis: true,
        }
      ];
      return columns;
    },
  },mounted() {
    this.getGroupTopUpStatistics();
  }
};
</script>
<style scoped>
.table-operations {
  margin-bottom: 16px;
}

.table-operations > button {
  margin-right: 8px;
}
</style>