<template>
  <div style="width: 100%;height: 106.2rem">
    <header id="header">
      <div class="inner clearfix">
        <div style="float: left;" class="div_img">
          <img src="../assets/images/shangbiao.jpg" style="margin-top: -1.25rem">
        </div>
        <div style="font-size: 20px;float: left;margin-left: 20px;margin-top: 8px;">旻泽支付平台</div>
        <nav class="menu" style="float: left;">
          <ul class="clearfix" style="margin-top: 1rem">
            <li><a target="_blank" rel="noopener">对接文档</a></li>
            <li><a target="_blank" rel="noopener">产品介绍</a></li>
          </ul>
        </nav>
        <div class="login" style="margin-top: 1rem">
          <a-icon type="phone"/>
          <a style="margin-left: 2px">客服中心</a>
          <a-button style="margin-left: 1rem" @click="goRegister">注 册</a-button>
        </div>
      </div>
    </header>
    <div :class="gif_box">
      <div class="login_box">
        <div style="height: 3.125rem">
          <div style="font-size: 1.25rem;color: #1E90FF;display: inline">
            登 陆
          </div>
          <div style="font-size: 1rem;color: #333333; float: right"  @click="goRegister">
            注 册
            <a-icon type="caret-right"/>
          </div>
        </div>
        <div class="login_form" v-if="isLogin" style="margin-top: 1.875rem">
          <a-form-model :form="form" :label-col="{ span: 3 }" :wrapper-col="{ span: 19 }">
            <a-form-model-item label="账号:">
              <a-input
                  v-model="form.username" placeholder="请输入账号">
                <a-icon slot="prefix" type="user" style="color:rgba(0,0,0,.25)"/>
              </a-input>
            </a-form-model-item>
            <a-form-model-item label="密码:">
              <a-input type="password"
                       v-model="form.password" placeholder="请输入密码">
                <a-icon slot="prefix" type="lock" style="color:rgba(0,0,0,.25)"/>
              </a-input>
            </a-form-model-item>
            <!--选择框判断用户是否要保存信息-->
            <a-form-item class="" style="width: 6.25rem; height: 1.25rem">
              记住密码:
              <input style="width: 1.875rem;" type="checkbox"  v-model="form.store"/>
            </a-form-item>
            <!--        已修改成点击图片刷新验证码    <a-form-item class="btns">-->
            <!--              <a-button type="info" @click="resetLoginForm" style="margin-left: 5rem">刷新验证码</a-button>-->
            <!--            </a-form-item>-->
            <a-form-item class="btns" style="margin-top: 1.25rem">
              <a-button type="primary" @click="handleSubmit" style="width: 200px;margin-left: 50px" size="large">登录
              </a-button>
            </a-form-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="wrap">
      <dl class="g-box m-d14">
        <dt class="m-dt-t1">智能支付服务生态，让支付变得更智慧</dt>
      </dl>
      <div style="margin-top: 1.25rem;margin-left: 15%;margin-right: 15%;height: 25rem">
        <a-carousel autoplay>
          <div class="show5"></div>
          <div class="show2"></div>
          <div class="show1"></div>
          <div class="show3"></div>
          <div class="show4"></div>
        </a-carousel>
      </div>
    </div>
    <div :class="div_bottom">
      <h1 style="" align="center">接入我们</h1>
      <div style="margin-left: 18%;margin-right: 18%">
        <a-slider :marks="marks" :default-value="-1" disabled/>
        <div>
          <a-row :gutter="16" style="margin-left: 0.2rem;">
            <a-col :span="8">
              <a-card hoverable style="width: 15rem">
                <img
                    slot="cover"
                    alt="example"
                    src="../assets/images/zhuce.jpg"
                    height="200px"
                />
                <template slot="actions" class="ant-card-actions">
                  <a-icon key="setting" type="setting"/>
                  <a-icon key="edit" type="edit"/>
                  <a-icon key="ellipsis" type="ellipsis"/>
                </template>
                <a-card-meta title="注册账户" description="在线提交银行账户、联系方式等基本信息，并按指引完成账户验证">
                  <!--                <div style="font-size: 20px;margin-left: 25px">-->
                  <!--                  <a-icon type="user-add"/>-->
                  <!--                  注册账户-->
                  <!--                </div>-->
                </a-card-meta>
              </a-card>
            </a-col>
            <a-col :span="8">
              <a-card hoverable style="width: 15rem">
                <img
                    slot="cover"
                    alt="example"
                    src="../assets/images/shenhe.jpeg"
                    height="200px"
                />
                <template slot="actions" class="ant-card-actions">
                  <a-icon key="setting" type="setting"/>
                  <a-icon key="edit" type="edit"/>
                  <a-icon key="ellipsis" type="ellipsis"/>
                </template>
                <a-card-meta title="账户审核" description="联系客服人员进行账户审核，审核通过后，即可体验各项产品能力">
                  <!--                <div style="font-size: 20px;margin-left: 30px">-->
                  <!--                  <a-icon type="loading"/>-->
                  <!--                  账号审核-->
                  <!--                </div>-->
                </a-card-meta>
              </a-card>
            </a-col>
            <a-col :span="8">
              <a-card hoverable style="width: 12.5rem">
                <img
                    slot="cover"
                    alt="example"
                    src="../assets/images/hezuo.jpg"
                    height="200px"
                />
                <template slot="actions" class="ant-card-actions">
                  <a-icon key="setting" type="setting"/>
                  <a-icon key="edit" type="edit"/>
                  <a-icon key="ellipsis" type="ellipsis"/>
                </template>
                <a-card-meta title="对接应用" description="通过平台或者联系客服提供的Demo，按照教程或指引，对接上线">
                  <!--                <div style="font-size: 20px;margin-left: 30px">-->
                  <!--                  <a-icon type="retweet"/>-->
                  <!--                  对接业务-->
                  <!--                </div>-->
                </a-card-meta>
              </a-card>
            </a-col>
          </a-row>
        </div>
      </div>

      <div style="width: 100%;height: 9rem;background-color: #555555;margin-top: 3rem;">
        <div style="position: relative;">
          <h3 style="margin-left:12.5rem">联系我们</h3>
          <div class="qrcode" ref="qrCodeUrl"
               style="background-color: #555555;margin-left: 12.5rem;margin-top: 0.5rem"></div>
        </div>
        <div style="width: 100%;text-align: center;">本站严禁一切棋牌、赌博、私彩等违反国家法律法规的游戏使用</div>
        <div style="width: 100%;text-align: center;" @click="hrefHandle">版权所有:山东旻泽科技有限公司 鲁ICP备2020047191号-2</div>
      </div>

    </div>
  </div>


</template>
<script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/crypto-js/4.0.0/crypto-js.min.js"></script>
<script>
import QRCode from 'qrcodejs2'
import aesAndenc from "@/components/utlis/aesAndenc";
export default {
  //加密密钥
  data() {
    return {
      marks: {
        33.33: '',
        66.66: '',
        100: '',
      },
      // 二维码信息
      link: 'http://www.nb130.com',
      form: {
        username: this.getCooke('username'),
        password: this.getCooke('password'),
        code: '',
        //用户输入完毕的信息进行加密处理
        passwordCooke:'',
        //新添加用户存储
        store:false
      },
      //显示登陆页面
      isLogin: true,
      // 显示注册页面
      isRegister: false,
      // 显示忘记密码页面
      isForget: false,
      // 验证码url
      imgUrl: "/platform/user/code",
      //尝试
      gif_box: '',
      div_bottom: '',
    }
  },
  methods: {
    // * 清空列表
    resetLoginForm() {
      this.imgUrl = "/platform/user/code?s=" + new Date();
    },
    // 去注册页面
    goRegister() {
      this.$router.push('/Register')
    },
    // 创建二维码
    creatQrCode() {
      new QRCode(this.$refs.qrCodeUrl, {
        text: this.link, // 需要转换为二维码的内容
        width: 70,
        height: 70,
        colorDark: '#000000',
        colorLight: '#eaedf2',
        correctLevel: QRCode.CorrectLevel.H
      })
    },
    // *点击提交
    async handleSubmit() {
      if (this.form.password === '') {
        this.$message.error("请输入用户名")
        return
      }
      if (this.form.username === '') {
        this.$message.error("请输入密码")
        return
      }
      //判断密码不为空进行加密上传到后端进行Cookie保存
      if(this.form.password !== ''){
        //采用8位算法进行加密
        var asekey = 12345678;
        //执行加密操作
        this.form.passwordCooke = aesAndenc.encrypt(this.form.password,asekey)
      }
      const {data: data} = await this.$http.post('/platform/user/adminLogin', this.form)
      if (data.code === 0) {
        this.$message.success("登陆成功")
        window.sessionStorage.setItem('token', data.token)
        window.sessionStorage.setItem('meuns',data.menus)
        window.sessionStorage.setItem('username',data.username)
        this.$router.push('/Home')
      } else {
        this.$message.error(data.message)
        this.resetLoginForm()
      }
    },
    //获取Cookie
    getCooke(param){
      //获取Cookie存储的数据并且进行分割
      console.log(document.cookie)
      if(document.cookie !== null && document.cookie !== ''){
        var user = document.cookie.split(';')
        //判断是否是用户名
        if (param === 'username'){
          //对用户名进行分割
          let username = user[0].split('=')
          //获取到用户名进行返回
          return username[1]
        }
        //判断是否是密码
        if(param === 'password'){
          var aseKey = 12345678;
          //对密码进行去除空格
          let password = user[1].trim()
          password = password.split('=')
          //进行解密此处因为分割会对后面两个等于号进行剔除所以这里手动进行了添加
          password[1]+='=='
          console.log(password[1])
          password = aesAndenc.decrypt(password[1],aseKey)
          console.log(password)
          return password
        }
      }
      param = ''
      return param
    },
    //用来判断pc还是手机
    isPhoneOrPc(){
      //获取浏览器型号用来判断手机还是pc
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      console.log(flag)
      //目前测试自己电脑这个是没有数据的
      if (flag === null){
        console.log("1"+flag)
        this.gif_box = "gif_box";
        this.div_bottom = 'div_bottom2';
      }else{
        console.log("2"+flag)
        this.div_bottom = 'div_bottom';
        this.gif_box = "gif_box2";
      }
      return flag;
    },
    hrefHandle(){
      window.open('https://beian.miit.gov.cn/')
    },
  },components:{
    'dingtalk':{
      render(createElement) {
        return createElement(
            'script',
            {
              attrs: {
                type: 'text/javascript',
                src: 'https://cdnjs.cloudflare.com/ajax/libs/crypto-js/4.0.0/aes.js',
              },
            },
        );
      },
    },
  },
  mounted() {
    this.isPhoneOrPc()
  }
}
</script>

<style lang="less" scoped>

nav {
  display: block;
}

//img {
//  width: 56px;
//  overflow: hidden;
//  margin: 0 auto;
//}
.div_img {
  width: 6.25rem;
  height: 3.5rem;
  overflow: hidden;
  position: relative;
}

.login_box {
  //z-index: 1;
  //position: absolute;
  //top: 50%;
  //left: 50%;
  //margin-left: 200px;
  //margin-top: -300px;
  //box-sizing: border-box;
  margin-left: 65%;
  margin-top: 10%;
  width: 22rem;
  height: 27rem;
  padding: 2rem 2rem 2.5rem;
  background: #fff;
  box-shadow: 0 1.5rem 2rem 0 rgba(63, 63, 65, .06);
  border-radius: 1rem;
}

//#img img {
//  width: 100%;
//  transition: all 2s; /*图片放大过程的时间*/
//  position: relative;
//}
//
//img:hover {
//  cursor: crosshair;
//  transform: scale(1.9); /*以y轴为中心旋转*/
//}

header {
  min-width: 69rem;
  position: fixed;
  top: 0;
  background-color: #fff;
  width: 100%;
  height: 3.5rem;
  z-index: 101;
  font-family: Helvetica, PingFang SC, Microsoft YaHei, 微软雅黑;

  .inner {
    height: 3.5rem;
    width: 68rem;
    margin: 0 auto;
    position: relative;

    .menu {
      margin-left: 5rem;
      text-align: center;
      height: 5rem;
      float: left;

      ul {
        margin: 0;
        padding: 0;
        display: inline-block;
        list-style: none;
        padding: 0;

        li {
          text-decoration: none;
          list-style: none; //去掉li前面的圆点
          display: inline; //让li横向排列
          border: 1px solid #FFFFFF;
          margin-left: 3.75rem;

          a {
            height: 100%;
            display: inline-block;
            color: #555;
            vertical-align: middle;
          }
        }
      }
    }

    .login {
      float: right;
    }
  }
}

.div_bottom{
  width: 100rem;
  height: 500px;
  margin-top: 40px
}
.div_bottom2{
  width: 100%;
  height: 500px;
  margin-top: 40px
}
.gif_box{
  background: url("../assets/images/bg_top.gif");
  width: 100%;
  height: 44rem;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  overflow: hidden;
  //text-align:center;
}
.gif_box2{
  background: url("../assets/images/bg_top.gif");
  width: 100rem;
  height: 44rem;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  overflow: hidden;
  //text-align:center;
}
.wrap {
  min-width: 75rem;
  width: 100%;
  position: relative;
}

.m-dl4 {
  padding-top: 6rem;
  padding-bottom: 7rem;
}

.g-box {
  width: 75rem;
  height: 100%;
  margin: 0 auto;
}

.m-dt-t1 {
  text-align: center;
  font-size: 2rem;
  margin-top: 2rem;
}

ul, li {
  margin: 0;
  padding: 0;
}

.f4-ul > li {
  display: inline-block;
  width: 15.5rem;
  height: 21.25rem;
  border: 1px solid #e3e2e5;
  margin-right: -1px;
  position: relative;
  vertical-align: top;
  overflow: hidden;
}

.show1 {
  background: url('../assets/images/show_1.jpg');
  background-size: 100% 100%;
  height: 25rem;
  background-repeat: no-repeat;
}

.show2 {
  background: url('../assets/images/show_2.jpeg');
  background-size: 100% 100%;
  height:  25rem;
  background-repeat: no-repeat;
}

.show3 {
  background: url('../assets/images/show_3.jpeg');
  background-size: 100% 100%;
  height:  25rem;
  background-repeat: no-repeat;
}

.show4 {
  background: url('../assets/images/show_4.jpeg');
  background-size: 100% 100%;
  height: 25rem;
  background-repeat: no-repeat;
}

.show5 {
  background: url('../assets/images/show_5.jpg');
  background-size: 100% 100%;
  height: 25rem;
  background-repeat: no-repeat;
}

</style>
