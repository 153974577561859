<template>
  <div>


    <a-card title="充值订单" :bordered="false" style="width: 100%">
      订单号:
      <a-input v-model="form.orderNo" style="width: 140px"/>
      玩家账号:
      <a-input v-model="form.playerName" style="width: 140px"/>
      时间:
      <a-range-picker @change="onChangeDate"/>
      <a-select style="width: 100px" v-model="form.orderStatus">
        <a-select-option value="-1">
          订单状态
        </a-select-option>
<!--        <a-select-option value="0">-->
<!--          待付款-->
<!--        </a-select-option>-->
        <a-select-option value="1">
          已支付
        </a-select-option>
        <a-select-option value="2">
          已通知
        </a-select-option>
<!--        <a-select-option value="3">-->
<!--          已结算-->
<!--        </a-select-option>-->
      </a-select>

      <a-select style="width: 100px" v-model="form.payType">
        <a-select-option value="-1">
          支付方式:
        </a-select-option>
        <a-select-option value="1">
          微信扫码
        </a-select-option>
        <a-select-option value="2">
          微信
        </a-select-option>
        <a-select-option value="3">
          支付宝扫码
        </a-select-option>
        <a-select-option value="4">
          支付宝
        </a-select-option>
      </a-select>
      <a-button type="primary" style="float:right" @click="orderByOrder">查询</a-button>
      <a-table :columns="columns" :data-source="data" size="small" rowKey="orderId"
      >
        <span slot="type" slot-scope="tags">
          <span v-if="tags === 1">微信扫码</span>
          <span v-else-if="tags === 2">微信</span>
          <span v-else-if="tags === 3">支付宝扫码</span>
          <span v-else-if="tags === 4">支付宝</span>
          <span v-else>出错啦!</span>
        </span>
        <span slot-scope="time" slot="time">
         {{$moment(time).utcOffset(480).format('yyyy-MM-DD HH:mm:ss')}}
        </span>
        <span slot="status" slot-scope="index, row">
          <span v-if="index === 0" style="color: #708090">待付款</span>
          <span v-else-if="index === 1" style="color: red">已支付<a-button style="margin-left:5px;color: #001135"
                                                                          size="small" type="primary"
                                                                          @click="headNotify(row)">手动通知</a-button></span>
          <span v-else-if="index >= 2" style="color: #ADFF2F">已通知</span>
<!--          <span v-else-if="index === 3" style="color: #1E90FF">待结算</span>-->
<!--          <span v-else-if="index === 4" style="color: #2ddb71">已结算</span>-->
          <span v-else>出错啦!</span>
        </span>
      </a-table>
    </a-card>
  </div>
</template>
<script>
import jwt from 'jsonwebtoken'
const columns = [
  {
    title: '订单编号',
    dataIndex: 'order_no',
    key: 'order_no',
    width: '200px'
  },
  {
    title: '玩家',
    dataIndex: 'player_name',
    key: 'player_name',
    width: '150px'
  },
  {
    title: '支付方式',
    width: '120px',
    dataIndex: 'pay_type',
    key: 'pay_type',
    scopedSlots: {customRender: 'type'}
  },
  {
    title: '金额(元)',
    width: '80px',
    dataIndex: 'order_amount',
    key: 'order_amount',
  },
  {
    title: '创建时间',
    width: '280px',
    dataIndex: 'create_time',
    scopedSlots: {customRender: 'time'}
  },
  {
    title: '支付状态',
    key: 'order_status',
    width: '150px',
    dataIndex: 'order_status',
    scopedSlots: {customRender: 'status'}
  },
  {
    title: '分区名称',
    key: 'zoneName',
    width: '150px',
    dataIndex: 'zoneName',
  },
];

export default {
  data() {
    return {
      data: [],
      columns,
      // 添加分区显示
      visible: false,
      // 抽屉显示方式
      placement: 'bottom',

      id: '',
      form: {
        orderNo: '',
        groupName: '',
        playerName: '',
        createTime: '',
        updateTime: '',
        orderStatus: '-1',
        payType: '-1'
      },
      orderId: ''
    };
  },
  methods: {
    // 初始化方法
    async init() {
      //获取当前用户id进行查询
      let userId = sessionStorage.getItem('token');
      userId = jwt.decode(userId)
      userId = userId.userId
      const {data: res} = await this.$http.post('/platform/order/queryCurrentUserAllOrderByUserId/'+userId);
      console.log(res);
      if (res.code === 0){
        this.data = res.orderTamplates;
      }
      // const {data: res} = await this.$http.post('/platform/order/list', this.form)
      // if (res.code === 0) {
      //   this.data = res.list;
      // }
    },
    async orderByOrder(){
      //获取当前用户id进行查询
      let userId = sessionStorage.getItem('token');
      userId = jwt.decode(userId);
      userId = userId.userId;
      const {data: res} = await this.$http.post('/platform/order/queryCurrentUserAllOrderByOrderAll/'+userId,this.form);
      if (res.code === 0){
        this.data = res.orderTamplates;
      }
    },
    onChangeDate(date, dateString) {
      let str = dateString.toString().split(',');
      this.form.createTime = str[0]
      this.form.updateTime = str[1]
    },
    onClose() {
      this.visible = false;
    },
    // 手动通知
    async headNotify(row) {
      const {data: res} = await this.$http.post('/platform/notify/headNotify', {orderNo: row.orderNo})
      if (res.code == 0) {
        this.$message.success(res.message)
      } else {
        this.$message.error(res.message)
      }
    }


  },
  mounted() {
    this.init()
  }
};
</script>
<style>
.ant-drawer-content-wrapper {
  height: 150px !important;
}

</style>
