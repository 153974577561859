<template>
  <div class="big_box">
    <div class="main-cont-wrap">
      <div style="height: 50px">
        <div style="font-size: 20px;color: #1E90FF;display: inline">
          注 册
        </div>
        <div style="font-size: 16px;color: #333333; float: right" @click="goLogin">
          登 陆
          <a-icon type="caret-right"/>
        </div>
      </div>
      <div style="margin-top: 15px;height: 450px;overflow: auto">
        <a-form-model
            :rules="rules"
            ref="ruleForm"
            :model="form">
          <a-form-model-item prop="username" ref="username" style="display: inline;white-space:nowrap;">
            <label>账号: </label>
            <a-input @blur="() => {
            $refs.username.onFieldBlur();
          }" v-model="form.username" placeholder="输入账号用来登陆系统" style="margin-left:10px;width: 250px;"/>
          </a-form-model-item>
          <a-form-model-item style="display: inline;white-space:nowrap;" ref="passwordHash" prop="passwordHash">
            <label>密码: </label>
            <a-input @blur="() => {
            $refs.passwordHash.onFieldBlur();
          }" v-model="form.passwordHash" type="password" placeholder="输入密码" style="margin-left:10px;width: 250px;"/>
          </a-form-model-item>

          <a-form-model-item style="display: inline;white-space:nowrap;" ref="userEmail" prop="userEmail">
            <label>邮箱: </label>
            <a-input @blur="() => {
            $refs.userEmail.onFieldBlur();
          }" v-model="form.userEmail" placeholder="输入邮箱" style="margin-left:10px;width: 250px;"/>
          </a-form-model-item>
          <a-form-model-item style="display: inline;white-space:nowrap;" ref="userCardName" prop="userCardName">
            <label>姓名: </label>
            <a-input @blur="() => {
            $refs.password.onFieldBlur();
          }" v-model="form.userCardName" placeholder="输入真实姓名" style="margin-left:10px;width: 250px;"/>
          </a-form-model-item>
<!--          <a-form-model-item style="display: inline;white-space:nowrap;" ref="userCardBankName" prop="userCardBankName">-->
<!--            <label>银行: </label>-->
<!--            <a-select v-model="form.userCardBankName" placeholder="银行名称" style="margin-left:10px;width: 250px;">-->
<!--              <a-select-option value="中国银行">中国银行</a-select-option>-->
<!--              <a-select-option value="中国建设银行">中国建设银行</a-select-option>-->
<!--              <a-select-option value="中国工商银行">中国工商银行</a-select-option>-->
<!--            </a-select>-->
<!--          </a-form-model-item>-->
          <a-form-model-item style="display: inline;white-space:nowrap;" ref="userCardNo" prop="userCardNo">
            <label>支付宝: </label>
            <a-input @blur="() => {
            $refs.userCardNo.onFieldBlur();
          }" v-model="form.userCardNo" placeholder="输入支付宝账号" style="margin-left:10px;width: 250px;"/>
          </a-form-model-item>
          <a-form-model-item style="display: inline;white-space:nowrap;" ref="userQq" prop="userQq">
            <label>微信: </label>
            <a-input @blur="() => {
            $refs.userQq.onFieldBlur();
          }" v-model="form.userQq" placeholder="输入微信或者QQ" style="margin-left:10px;width: 250px;"/>
          </a-form-model-item>
          <a-form-model-item style="display: inline;white-space:nowrap;" ref="userPhone" prop="userPhone">
            <label>手机: </label>
            <a-input @blur="() => {
            $refs.userPhone.onFieldBlur();
          }" v-model="form.userPhone" placeholder="手机号" style="margin-left:10px;width: 250px;"/>
          </a-form-model-item>
          <a-form-model-item style="display: inline;white-space:nowrap; margin-top: 20px">
            <label>验证码: </label>
            <a-input placeholder="验证码" style="margin-left:10px;width: 100px;" v-model="form.code"/>
            <img class="img_vcode" style="width: 100px;height: 60px;margin-left: 20px" alt="" @click="resetLoginForm"
                 :src="imgUrl"/>
          </a-form-model-item>
          <a-form-model-item style="display: inline;white-space:nowrap;">
            <a-button type="primary" style="width: 200px;margin-left: 50px;margin-top: 10px" @click="onSubmit"
                      size="large">注 册
            </a-button>
          </a-form-model-item>

        </a-form-model>


      </div>

    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      imgUrl: "/platform/user/code",
      rules: {
        userCode: [
          {required: true, message: '请输入用户名', trigger: 'blur'},
          {min: 5, max: 15, message: '账号长度控制在5-15之间', trigger: 'blur'},
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 5, max: 15, message: '密码长度控制在5-15之间', trigger: 'blur'},
        ],
        userEmail: [
          {required: true, message: '请输入邮箱', trigger: 'blur'},
          {pattern: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/, message: '邮箱格式有误', trigger: 'blur'},
        ],
        userCardName: [
          {required: true, message: '请输入真实姓名', trigger: 'blur'},
          {min: 1, max: 10, message: '姓名长度异常', trigger: 'blur'},

        ],
        userPhone: [
          {required: true, message: '请输入真实手机号', trigger: 'blur'},
          {
            // pattern: /^(13[0-9]|14[5|7]|15[0|1|2|3|4|5|6|7|8|9]|18[0|1|2|3|5|6|7|8|9])\d{8}$/,
            pattern: /^1[3-9]\d{9}$/,
            message: '手机号格式有误',
            trigger: 'blur'
          },
        ],
        userCardNo: [
          {required: true, message: '请输入支付宝账号', trigger: 'blur'},
        ],
      },
      form: {
        // 账号
        username: '',
        // 密码
        passwordHash: '',
        // 邮箱
        userEmail: '',
        // 姓名
        userCardName: '',
        // QQ
        userQq: '',
        // 银行名
        userCardBankName: '6789',
        // 卡号
        userCardNo: '',
        // 验证码
        code: '',
        // 手机号
        userPhone: ''


      }
    }
  },
  methods: {
    goLogin() {
      this.$router.push('/login')
    },
    resetLoginForm() {
      this.imgUrl = "/platform/user/code?s=" + new Date();
    },
    onSubmit() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          const {data: data} = await this.$http.post('/platform/user/register', this.form)
          if (data.code === 0) {
            let key = 'loadingKey'
            this.$message.loading({content: 'Loading...', key})
            setTimeout(() => {
              this.$message.success({content: '注册成功!', key, duration: 2});
              this.$router.push('/login')
            }, 1000);
          } else {
            this.$message.error(data.message)
            this.resetLoginForm()
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  }
}
</script>
<style>
.big_box {
  background: url('../../assets/images/registerbg.jpg');
  width: 100%;
  height: 100%;
}

.main-cont-wrap {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -190px;
  margin-top: -255px;
  box-sizing: border-box;
  width: 380px;
  height: 550px;
  padding: 30px 30px 40px;
  background: #fff;
  box-shadow: 0 20px 30px 0 rgba(63, 63, 65, .06);
  border-radius: 10px;
}

a-input {
  width: 100px !important;
}
</style>
